.LY_KanbanBoard_Column {
    width: 100%;
    overflow-y: hidden;
    display: flex;
  /*   padding: 20px; */
    gap: 10px;
    padding-bottom: 10px;
    max-height: 70vh;
}



.LY_KanbanBoard_New_Column:hover {
    border-color: #888888;
}


.LY_KanbanBoard_New_Column {
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: #F5F8FA;
    border-radius: 8px;
    padding: 12px;
    column-gap: 10px;
    height: fit-content;
    min-width: 250px;
}

.kanban-page {
    overflow: hidden !important;
}