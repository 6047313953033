 

.LY_NameColumnInputContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 10px;
  gap: .6rem;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
}
.LY_NameColumnInputContainerCard{
  margin: 0px;
}

.LY_NameColumnInputActions {

  position: absolute;
     display: none;  
    /* display: flex; */
  justify-content: center;
  align-items: center;
  gap: 5px;
  z-index: 100;
  right: 0px;
  background-color: #F5F5F5;
}

.LY_NameColumnInputContainer:hover {

  .LY_NameColumnInputActions {
    display: flex;
    
  }

}

.LY_NameColumnInputActionsButton{
  display: flex;
  justify-content: center;
  padding: 3px;
  color: var(--mantine-primary-color-filled);
  cursor: pointer;
  background-color: #F5F5F5;
}

.LY_NameColumnInputActionsButton:hover{
  background-color: var(--action-btn-bg);
  color: var(--mantine-primary);

}

.LY_NameColumnInputLink{
  color: var(--mantine-color-black) !important;
  text-decoration: none;
  flex:1;
}
