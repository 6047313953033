.LY_DoubleColorInput_item_left_section_wrapper {
    display: flex;
    flex-direction: row !important;
    position: absolute !important;
    align-items: center !important;
    justify-content: center !important;
    top: unset !important;
    left: 15px !important;
    margin: 0 !important;
    height: 100% !important;
    --input-margin-bottom: 0px !important;
}

.LY_DoubleColorInput_item_input_wrapper {
    display: flex;
    width: 100%;
    padding: 1px;
    position: relative;
    flex: 1 !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 4px;
    margin: 0 !important;
    margin-bottom: 0px !important;
    bottom: 0px !important;

}

.LY_DoubleColorInput_item_input_root {
    width: 100% !important;
}

.LY_DoubleColorInput_item_input {
    display: flex;
    font-size: 12px !important;
    flex: 1 !important;
    padding: 0 !important;
    margin: 0 !important;
    padding-left: 60px !important;
    border-radius: 8px !important;
    font-size: 12px !important;
}

.LY_DoubleColorInput_item_left_section {
    display: flex;
    flex-direction: row !important;
    gap: 5px;
    width: max-content;
    align-items: center !important;
    justify-content: center !important;
    height: 100% !important;
}

.LY_DoubleColorInput_item_picker_input_wrapper {
    margin-bottom: 5px !important;
    width: 100%;
}

.LY_DoubleColorInput_item_picker_input {
    border-radius: 8px !important;
    border: 1px solid #CED4DA !important;
}

.LY_DoubleColorInput_item_picker_saturation {
    height: 70px !important;
}

.LY_StaticSelect_ButtonGroup {
    width: 100% !important;
    display: flex;
    justify-content: flex-end;
    column-gap: 10px;
    border-top: 1px solid #F1F1F1;
    padding: 5px 12px;
}

.LY_StaticSelect_ButtonGroup_Cancel {
    border: 1px solid #CED4DA !important;
    box-shadow: 0px 2px 0px 0px #00000004 !important;
    color: #535964 !important;
    border-radius: 4px !important;
    cursor: pointer;
}

.LY_StaticSelect_ButtonGroup_Save {
    border-radius: 4px !important;
    cursor: pointer;
}

.LY_DoubleColorInput_item_color_picker {
    width: 22px;
    height: 22px;
    border-radius: 50% !important;
    cursor: pointer;

}