.LY_NewWorkspaceModule_Modal_Search .mantine-Input-input {
    padding-left: 30px;
}

.LY_NewWorkspaceModule_Modal_Blank {
    border: 1px solid #CED4DA;
    width: 100%;
    height: 150px;
    border-radius: 15px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.LY_NewWorkspaceModule_Modal_TemplatesTitle {
    margin-top: 20px;
    padding-bottom: 15px;
}


.LY_NewWorkspaceModule_Modal_TemplatesScroll {
    height: 350px;
    margin-top: 20px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-right: 10px;
    padding-left: 5px;
}

.LY_NewWorkspaceModule_Modal_CardContent {
    display: flex;
    gap: 10px;
}

.LY_NewWorkspaceModule_Modal_CardContent_Title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.LY_NewWorkspaceModule_Modal_BottomButtons {
    margin-top: 10px;
    display: flex;
    justify-content: end;
    gap: 10px;
}

.LY_NewWorkspaceModule_Modal_TemplatesLoader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
}

.LY_NewWorkspaceModule_Modal_Selected {
    box-shadow: 0 0 0 2px #007AFF !important;
    background-color: #F7E4FD !important;
    
}

.LY_NewWorkspaceModule_WorkspaceTemplate {
    cursor: pointer;

}

.LY_NewWorkspaceModule_WorkspaceTemplate:hover {
    background-color: #F7E4FD;
}

.LY_NewWorkspaceModule_Modal_FormContainer {
    min-height: 300px;
}

.LY_NewWorkspaceModule_Modal_CardContent_Info {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
