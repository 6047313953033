.LY_ListFiltersModule_Filter_body_group_wrapper {
  display: flex;
  align-items: flex-start;
  column-gap: 10px;
}

.LY_ListFiltersModule_Filter_body_group {
  display: flex;
  flex-direction: column;
  background-color: #f7f7f7;
  row-gap: 10px;
  align-items: self-start;
  padding: 12px;
}

@media screen and (max-width: 768px) {
  .LY_ListFiltersModule_Filter_body_group_wrapper {
    flex-direction: column;
    row-gap: 12px;
  }
}