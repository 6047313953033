.ListDetailPanelContainer {
    padding: .7rem;
/*     height: 100%; */
}

.ListDetailPanel {
    display: flex;
}

.ListDetailPanelContent {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: calc(100vh - 300px);
}

.DetailRightPanelCloseBtn{
    position: absolute;
    left: -8px;
    top: 0;

}

.DetailRightPanelHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: .2rem;
}