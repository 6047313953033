:root {

  --color-solid-resize-bar: #ebeff9;
  /* #454950; */
  --color-solid-resize-bar-handle: rgba(190, 190, 190, 0.2);
}
 

.MainSplitPageContainer {
/*   max-height: 100vh; */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  /* gap: 1rem; */
  background-color: transparent;

/*   margin-top: 53px; */
/*   padding-top: 53px; */
 /*  padding-bottom: 5px; */
}

.MainSplitPagePanel {

  overflow: auto !important;
}

/*   .MainSplitPagePanel:hover {
 
    overflow: auto !important;
  } */

.MainSplitPagePanel::-webkit-scrollbar {
  width: var(--ly-global-scrollbar-width);
}

.MainSplitPagePanel::-webkit-scrollbar-track {
  background: var(--ly-global-scrollbar-track-color);
}

.MainSplitPagePanel::-webkit-scrollbar-thumb {
  background: var(--ly-global-scrollbar-thumb-color);
  cursor: pointer;
}

.MainSplitPagePanel::-webkit-scrollbar-thumb:hover {
  background: var(--ly-global-scrollbar-thumb-hover-color);
}

.MainSplitPage-MainPanels {
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;
}

.MainSplitPagePanelContent,
.MainSplitPagePanelContent-LeftMenu {
  /*  display: flex;  */

  border-radius: 5px;
  padding: .7rem;
  padding-right: 0;
  padding-top: 0;
  height: 100%;
}

.MainSplitLeftPanels {
  border-top-right-radius: 15px !important;
  background-color: white;
  min-height: calc(100vh - 53px);
}

.MainSplitPagePanelContent {
 /*  border-radius: 15px !important; */
  border-radius:0;
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
  background-color: white;
  margin-inline: 8px;
  margin-right: 10px;
  height: 100%;
  overflow: hidden;
  min-height: calc(100vh - 53px);
}

.MainSplitPagePanelContent-LeftMenu {
  padding-right: 5px;
  padding-top: 1rem;

}

.MainSplitPageResizeHandleOuter {
  flex: 0 0 0.3rem;
  position: relative;
  outline: none;

  background-color: var(--color-solid-resize-bar);
}

.MainSplitPageResizeHandleOuter[data-resize-handle-active] {

  background-color: var(--color-solid-resize-bar-handle);
}

.MainSplitPageResizeHandleInner {
  position: absolute;
  top: 0.25em;
  bottom: 0.25em;
  left: 0.25em;
  right: 0.25em;
  border-radius: 0.25em;
  background-color: var(--background-color);
  transition: background-color 0.2s linear;
}

.MainSplitPageButton:hover {
  background-color: var(--color-button-background-hover);
}

.MainSplitPageIcon {
  width: 1em;
  height: 1em;
  position: absolute;
  left: calc(50% - 0.5rem);
  top: calc(50% - 0.5rem);
}

 
.LY_MainSplitPageFullPageHeight{
 
  height: calc(100vh - 53px);
  .LY_MainContainerContent {
    overflow: auto !important;
  }

  
 }



 

.LY_MainSplitNarrowPage_MainPanels {
  max-width: 70vw !important; 
 
} 

.LY_MainSplitNarrowPage_MainPageContainer {
  display: flex;
  align-items: center;
  justify-content: center;

 }


.LY_MainSplitNarrowPage_MainPanelsContent{
  margin-inline: 10px;
  padding:.7rem !important;
  background-color: white;
  border-radius:0;
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
  width: 70vw !important; 
  
}
@media (max-width: 768px) {
  .LY_MainSplitNarrowPage_MainPanelsContent {
    width: 100% !important; 
   
  }
  .LY_MainSplitNarrowPage_MainPanels {
     width: 100% !important; 
 
  }
  .LY_MainSplitNarrowPage_MainPageContainer{
    padding-inline:.7rem !important;
  }
}
