*{
  --input-bd: var(--mantine-color-gray-4);
  --input-bg: var(--mantine-color-white);
  --input-bd-focus: var(--mantine-primary-color-filled);
}
.LY_SelectInput {
  border-radius: var(--input-radius);
  padding: 0 8px;
  font-size: 14px;
  line-height: 1.4;
  transition: all 0.2s ease;
  height: 36px;
  width: 100%;
  cursor: pointer;
  height: var(--input-size);
  min-height: var(--input-height);
  line-height: var(--input-line-height);
  border: calc(0.0625rem* var(--mantine-scale)) solid var(--input-bd);
}
.LY_SelectInput:focus {
 
  border: calc(0.0625rem* var(--mantine-scale)) solid var(--input-bd-focus);
  outline: none;
}
option {
  font-weight: normal;
  display: block;
  min-height: 1.2em;
  padding: 0px 2px 1px;
  white-space: nowrap;
}