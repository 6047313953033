.LY_StatusDropdown_Styles_Wrapper {
    padding: 8px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.LY_StatusDropdown_Styles_select_input {
    border-radius: 8px !important;
    border: 1px solid #CED4DA !important;
}

.LY_StatusDropdown_Styles_select_label {
    margin-bottom: 10px;
}

.LY_StatusDropdown_Styles_Radius_Wrapper {
    margin-bottom: 20px;
}

.LY_StatusDropdown_Styles_Button {
   /*  height: 100px; */
    display: flex;
    justify-content: center;
    align-items: center
}