/* .UpdateColumnScreenModal{
  width:30rem;
} */
.UpdateColumnAdvancedAccordionControl{
  padding: unset !important;
  padding-left:5px !important;
 
}
.UpdateColumnTabPanel{
  padding-top: 10px;
  padding-right: 10px;
  max-height: 50vh;
  overflow: auto;
}

.UpdateColumnTabContainer{

  min-height: 450px;

}
.UpdateColumnAdvancedAccordion{
 
  margin-top:.5rem;

  margin-right:5px; 
 /*  margin-right:1rem; */


}


.UpdateColumnScreenForm{
  padding-top: .5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: auto;
/*   max-height: 450px;
   overflow: auto;  */
 font-size: inherit !important;


}
.UpdateColumnScreenFormScrollArea {
  gap: 10px;
  display: grid;
  grid-template-columns: 1fr;
  overflow: auto;
  height: 100%;
}
 
.UpdateColumnAdvancedTabGroups{
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: left;
  justify-content: left;
  
}

/* ResponsiveModal.css */
.responsive-modal {
  max-height: 60vh; /* Default max height for large screens */
}
 

@media (max-width: 768px) {
  .responsive-modal {
    max-height: 80vh; /* For small screens */
  }
}

.UpdateColumnAdvancedAccordionControlLabel{
  padding-block: 8px !important;
}
 
