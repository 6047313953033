.LY_Icon {
    /* width: 32px; */
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.LY_Icon svg {
    opacity: 0;
}

.LY_TabSection:hover .LY_Icon svg {
    opacity: 1;
}

.LY_Tabs_Tab_sortable_item-handle {
    cursor: grab;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2px;
}

.LY_TabSection_GripIcon {
    opacity: 0;
}

.LY_TabSection:hover .LY_TabSection_GripIcon {
    opacity: 1;
}

.LY_TabSection_Icon_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: grab;
}
.LY_ListPageViewTabSectionItem{
    text-decoration: none;
    color: var(--mantine-color-text);
 
}