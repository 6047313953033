 
 .LY_TagsInputContainerInput, .LY_TagsInputContainerWrapper, .LY_TagsInputContainerRoot{
  line-height: unset !important;
  min-height: unset !important;
/*   height: 100% !important; */
  margin:0 !important;
} 
.LY_TagsInputContainerInput {
  margin:0 !important;
   border: 0 !important;    
/*  border: 1px solid var(--ly-input-border-color) !important;
 */  

 display: flex !important;  
 flex-direction: row !important;  
 height:  unset !important;
}
.LY_TagsInputContainerWrapperForm{
  border: 1px solid var(--ly-input-border-color) !important;
}


.LY_TagsInputContainerInputField{
/*   height: 100% !important; */
   width: 100% !important;
   margin:0 !important;
  flex:1 1 !important;

/*   border:  1px solid black; */
/*   z-index: 100; */
/*  flex-wrap: wrap !important; */
}
/* .LY_TagsInputContainerPillsList{
  flex-wrap: nowrap !important;
} */