.ListPageContent{
 
  margin: 0px;
  margin-top: 10px;
  margin-right: 0;
}  
/* .ListPageTabs, .ListPageTabPanel{
  height: 100%;
} */
.ListPageTabPanel{
  height: 100%;
}
.ListPageTabsContainer{
  height: auto;
}
/* .MainSplitPageMainPanelsForList{
  height: 100vh;
} */
.MainSplitPageContainerForList {
  max-height: 100vh; 

}
.ListPageTitle{
  margin-left: 10px;
}

.LY_ListPageViewTab{
  padding: 0px !important;
  margin: 0px !important;
  padding-left: 2px !important;
  padding-block: 10px !important;
}
.LY_ListPageViewTabSection{
  margin-left: 4px !important;
  margin-right: 4px !important;
}
.LY_TabsContextMenuButtonContainer{
  min-width: 22px;
}
.LY_TabsContextMenuButton{
  display: none !important;   
  /* padding: 0px !important;
  margin: 0px !important; */
  border-radius: 8px !important;
  margin-right: 5px !important;

}
.LY_TabsContextMenuButton:hover{
  border: 1px solid var(--ly-global-background-color);
  background-color: rgb(224, 224, 224) !important;
}


.LY_ListPageViewTab:hover{

  .LY_TabsContextMenuButton{
    display:flex !important;

  }
} 