
.LY_NotesModule_Note_Card {
    margin-bottom: 12px;
    width: 100%;
    display: flex;
    flex-direction: column;
   /*  row-gap: 16px; */
    border: .5px solid #CED4DA;
    border-radius: 4px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.LY_NotesModule_Note_Card:last-of-type {
    margin-bottom: 0;
}

.LY_NotesModule_Note_Card_Content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px 14px 0 12px;
}

.LY_NotesModule_Note_Card_User_Info {
    display: flex;
    justify-content: space-between;
    justify-content: flex-start;
    column-gap: 9px;
    align-items: center;
}
.LY_NotesModule_Note_Card_Replies{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    color: #535964;
}
.LY_NotesModule_Note_Card_Content_Date{
    font-size: 11px;
    margin-right: 12px;
}
.LY_NotesModule_Note_Reply_Card_Footer{
     column-gap: 8px;
     padding: 8px;
     font-size: .8rem !important;
}
.LY_NotesModule_Note_Card_Content_Text{
    padding: 8px!important;
    padding-block: 3px !important;
    white-Space: pre-wrap;
}

.LY_NotesModule_Note_Card_Content_Text span {
    word-wrap: break-word;
}

.LY_NotesModule_Note_Reply_Card_Reply_Button {
    cursor: pointer;
}

.LY_NotesModule_Loading_Container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
}
