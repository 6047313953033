.LY_NoteModule_Main_Input {
    margin: 0 !important;
}

.LY_NoteModule_Main_Input_SendIcon{
    color: #007AFF;
    cursor: pointer;
}

.LY_NoteModule_Main_Input_Container{
    border-radius: 8px!important;
    margin: 0 !important;
    padding: 15px 30px 15px 15px !important;
    line-height: 24px !important;
}

.LY_NoteModule_Main_Input_Wrapper{
    margin: 0 !important;
}

.LY_Note_Module_IsReply {
    padding: 0 12px 16px 12px ;
    margin-bottom: 0 !important;
}

.LY_Note_Module_IsNewNote {
    padding: 0;
}

.LY_NoteModule_Main_Input_SendIcon_Link{
    display: flex;
    align-items: center;
}

