* {
  --input-bd: var(--mantine-color-gray-4);
  --input-disabled-bg: var(--mantine-color-gray-1);
  --input-bg: var(--mantine-color-white);
  --input-bd-focus: var(--mantine-primary-color-filled);
  --input-border-color: var(--ly-global-background-color);
  --ly-input-border-error-color:var(--mantine-color-error);
}

.LY_InputInput {
/*   padding-block: 3px; */
  border: 1px solid var(--input-border-color);

 
  resize: var(--input-resize, none);
  display: block;
  width: 100%;
  transition: border-color 100ms ease;
  text-align: var(--input-text-align);
  color: var(--input-color);
  border: 1px solid var(--input-bd) ;
  background-color: var(--input-bg) ;
  font-family: var(--input-font-family, var(--mantine-font-family));
  height: var(--input-size);
  min-height: var(--input-height);
  line-height: var(--input-line-height);
  font-size: var(--input-fz, var(--input-fz, var(--mantine-font-size-sm)));
  border-radius: var(--input-radius) !important;
  padding-inline-start:3px; /* var(--input-padding-inline-start,3px); */
  padding-inline-end: 3px; /* var(--input-padding-inline-end,3px); */
  padding-top: var(--input-padding-y, 0rem);
  padding-bottom: var(--input-padding-y, 0rem);
  cursor: var(--input-cursor);
  overflow: var(--input-overflow);
  border-radius: 0px;

  appearance: none;  
  outline: none;  
}
.LY_InputInput_focus:focus {
  border-color: var(--input-bd-focus);
 }
.LY_InputInput_error {
  border-color: var(--ly-input-border-error-color);
 
}
.LY_InputInput_disabled{
  background-color: var(--input-disabled-bg);
}
.LY_Input_ReadOnly{
  min-height: 1.5rem;
}