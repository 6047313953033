.LY_BulkEmailSenderModule_Form_Container{
  flex:1;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap:5px;
}
.LY_BulkEmailSenderModule_Form_SlashEditor_Body{
  height: 100% !important;
  line-height: 1;
}
.LY_BulkEmailSenderModule_Form_Content{
  overflow: auto;
  line-height: 1;
  height: 350px !important;
 
}
.LY_BulkEmailSenderModule_Form_Content_Inner{
  margin-top: 10px;
  white-space: pre-line;
  line-height: .8;
}