.LY_MenuButton {
    color: #8C8C8C !important;
    border-radius: 4px !important;
    border: 1px solid #8C8C8C !important;
    padding: 0 !important;
    width: 40px !important;
    height: 40px !important;
    /* display: none !important; */
  }

  .LY_MenuButton_Item {
    height: 30px !important;
    color: #535964 !important;
    font-size: 14px !important;
  }