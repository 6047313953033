.LY_Stepper_Step_Label_Active{
    color: #262626;
    font-size: 24px !important;
    font-weight: 500 !important;
}

.LY_Stepper_Step_Label{

    font-size: 24px !important;
    font-weight: 400 !important;
}

.LY_Stepper_Step_Separator {
    background-color: rgba(240, 240, 240, 1) !important;
}

.LY_Stepper_Step_Icon[data-progress] {
        background-color: rgba(24, 144, 255, 1)  !important;
        color: white !important;
        border: none !important;
}

.LY_Stepper_Step_Icon {
    background-color: transparent  !important;
    color: rgba(217, 217, 217, 1) !important;
    border: 1px solid rgba(217, 217, 217, 1) !important;
} 

.LY_Stepper_Step_Icon_Completed{
    background-color: white !important;
    color: rgba(24, 144, 255, 1) !important;
    border: 1px solid rgba(24, 144, 255, 1) !important;
    border-radius: 50% !important;
}