.LY_EmailComposeModule_Modal_Body {
    padding: 0 !important;
    overflow: hidden !important;
    height: 650px !important;
}

.LY_EmailComposeModule_Modal_Body_FullScreen {
    padding: 0 !important;
    overflow: hidden !important;
    height: 100% !important;
}

.LY_EmailComposeModule_Modal_Content{
    min-width: 1100px !important;
}