.LY_DLA_LabelMakerModule_Label_Container{
   display: flex;
  flex-direction: column; 
  align-items: center;
  justify-content: center;
  background-color: rgb(247, 247, 247);
}


.LY_DLA_LabelMakerModule_Label_Container div{
  text-transform: uppercase;
 /*  word-wrap: nowrap;
  white-space: nowrap; */
}
 