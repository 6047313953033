.DefaultListTableForm{
    display: flex;
    flex-direction: column;
    gap:.5rem;
    padding-bottom: 1rem;
}
 

.DefaultListTableFormContainer{
    display: grid !important;
 /*    grid-template-columns: 1fr 1fr 1fr  !important; */
    grid-template-columns: repeat(auto-fit, minmax(16vw, 1fr));
    grid-auto-flow: row;
 /*    grid-auto-flow: column !important; */
    grid-gap: 10px;
   /*  max-height: 200px;   */
    height: 100%;
    overflow: auto;
    padding-right: 5px;
    
}

.DefaultListTableFormContainerItem {
    display: flex !important;
    flex-direction: row;
    justify-content: space-between !important;

   /*  padding-top:5px; */
/*     border: 1px solid #e0e0e0; */
  }

  @media (max-width: 600px) {
    .DefaultListTableFormContainer {
      grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
      max-height: 400px;
    }
  }