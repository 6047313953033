.LY_ComposeEmail_Content_Container{
    width: 100%;
    height: 100%;
}

.LY_ComposeEmail_EmailPart_Navigation_Right {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-left: auto;
}

