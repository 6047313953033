.LY_NotesModule_Note_Reply_Card{
    padding: 10px;
    padding-left: 30px;
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    color: #535964;
}

.LY_NotesModule_Note_Reply_Card_Content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
}

.LY_NotesModule_Note_Reply_Card_Message_Container{
    background-color: #EBF1F5;
    padding: 13px!important;
    border-radius: 8px;
    white-Space: pre-wrap;
    font-size: 14px!important;
}

.LY_NotesModule_Note_Reply_Card_Content_Date{
    font-size: 11px;
    margin-right: 12px;
}

.LY_NotesModule_Note_Reply_Card_Pin_Filled{
     color: #007AFF;
     cursor: pointer;
     width: 16px !important;
}

.LY_NotesModule_Note_Reply_Card_Pin{
    color: #757B87;
    cursor: pointer;
    width: 16px !important;
}

.LY_NotesModule_Note_Reply_Card > .LY_Flex_Row {
    column-gap: 12px;
}

@media (max-width: 480px) {
    .LY_NotesModule_Note_Reply_Card_Content_Date{
        display: none;
    }
}