.LY_KanbanBoard_Item {
    display: flex;
    align-items: center;
    padding: 12px;
    border-radius: 8px;
    background-color: white;
    margin-bottom: 8px;
    transition: background-color 0.2s ease;
    border: 1px solid #0000001A;
    box-shadow: 0px 1px 4px 0px #0000000D;
    min-height: 122px;
    cursor: unset !important;
}

.LY_KanbanBoard_Item .dragging {
    background-color: #f0f8ff;
}

.LY_KanbanBoard_Item_content {
    flex-grow: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 5px;
}