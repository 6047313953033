 
._LY_InputBaseViewEditContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* width: 100%; */
  height: 100%;
  padding-left: 2px;
 /*  padding-right: 10px; */
  /* margin: 10px; */
/*   margin-left: 0; */
/*   padding-left:0px; */
  gap: .6rem;
  cursor: pointer;
  background-color: transparent;
  
}
._LY_InputBaseViewEditContainerViewMode{
  padding-left: 5px;
  width: 100%;

}
._LY_InputBaseViewEditContainerForm{
/*   border: 1px solid var(--ly-input-border-color) !important;
 */ 
  padding-left: 0px;
/*   align-items: flex-start; */
  align-items: center;
}

._LY_InputBaseViewEditActions {
  position: absolute;
  display: none;
  /*   display: flex; */
/*   display: flex; */
  justify-content: center;
  align-items: center;
  gap: 5px;
  z-index: 100;
  right: 0px;
  background-color: #F5F5F5;
 /*  min-width:20px !important; */
}

._LY_InputBaseViewEditContainer:hover {

  ._LY_InputBaseViewEditActions {
    display: flex;
    
  }

}

._LY_InputBaseViewEditActionsButton{
  display: flex;
  justify-content: center;
  /* padding: 3px; */
  color: var(--mantine-primary-color-filled);
  cursor: pointer;
  background-color: #F5F5F5;
  padding: 3px;

}

._LY_InputBaseViewEditActionsButton:hover{
  background-color: var(--action-btn-bg);
  color: var(--mantine-primary);

}

._LY_InputBaseViewEditLink{
  color: var(--mantine-color-black) !important;
  text-decoration: none;
}
