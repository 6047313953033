.LY_KanbanBoard_Column_Item {
    background-color: #F5F8FA;
    border-radius: 8px;
    min-width: 250px;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
}

.LY_KanbanBoard_Column_Item-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    margin-top: -7px;
}

.LY_KanbanBoard_Column_Item-header_wrapper {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    /* border-top: 8px solid; */
}

.dragging {
    background-color: #bcdff1;
}


.LY_KanbanBoard_Column_New_Task {
    display: flex;
    width: 100%;
    padding: 8px;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
    color: #00000080;
    cursor: pointer;
}

.ConfirmModal_Overlay {
    background: none !important;
}

.LY_KanbanBoard_Column_Border {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-style: solid;
    border-width: 3px;
}