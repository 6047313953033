.LY_PricingGridContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 2rem;
  gap:10px;
}

.LY_PricingGrid {
  display: grid;

  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  grid-auto-flow: row dense;
  grid-gap: 20px;
  align-items: center;
  justify-content: center;
  place-items: center;
  width: 100%;
  max-width: 1200px; 
}

.LY_PricingGridItem {
  width: 100%;
  justify-self: center;
  align-self: center;
}

.LY_PricingFeatureLine{

  display: flex;
  flex-direction: row;
  gap:5px;

}
.LY_SignUpButton{
  /* text-transform: uppercase; */
/*   background-color: red !important; */
 width: 100%;
 flex:1;
}

.LY_OfferStrikeThrough {
  position: relative;
  display: inline-block;
  color: black; /* Adjust as needed */
}

.LY_OfferStrikeThrough::after {
  content: "";
  position: absolute;
  top: 70%;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: red; 
/*   transform: rotate(-10deg);  */
}

.LY_PriceTag{
  display: flex;
  flex-direction: column;
  padding-top: 20px;
}

.LY_OfferInfoBox{
  width:100%;
  min-height: 70px;
  background-color: aquamarine;
  border-radius: 8px;
}
 

.price-and-features-card {
 
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border: 3px solid var(--ly-global-background-color);
/*   margin: 8px 8px; */
  border-radius: 8px;
  min-height: 520px;
}
.tier-color-bar-most-popular{
  background-color: rgb(0, 133, 255);
 
 }
 

.price-and-features-card .price-card-price-container {
 
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: flex-start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 25px;
  min-height: 300px;
}
 

.price-and-features-card .tier-color-bar {
  width: 100%;
  height: 10px;
  border-radius: 4px 4px 0 0;
}

.price-card-tier-name {
  position: relative;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
   height: 24px;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 25px;
 
}

@media (max-width: 900px) {
  .price-card-tier-name {

    flex-direction: column;
    height:auto;
    min-height: 24px;
  }
}

.price-card-price-per-month .plan-price {
  height: 50px;
  font-size: 2.7rem;
  margin-right: 8px;
  font-weight: 300;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;

}

.tier-includes-container {
  padding: 20px;
  font-weight: 400;
  font-size: 16px;
  font-family: Poppins, Roboto, Helvetica, Arial, sans-serif;

}

.price-and-features-card .tier-includes-container .tier-includes-title {
  margin-bottom: 16px;
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 22px;
  margin-top: unset;
}

.price-card-price-per-month .per-user-per-month {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-top: 8px;
  white-space: nowrap;
  text-align: left;
}

.price-card-price-per-month {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.price-card-price-per-month .per-user-per-month>span {
  font-weight: 300;
}

.region {
  padding: 25px;
}
 
.price-and-features-card .separator {
  width: 80%;
  margin: 0 10%;
  border: 1px solid #d5d5d5;
  margin-bottom: 10px;
}

.price-card-tier-name .tier-name-extra.with-square-top-text .extra-text {
  padding: 8px;
}
.tier-name-text{
  flex:1;
}
.tier-name-extra {
  font-size: 0.875rem;
  position: relative;
  margin-left: 15px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
 /*  margin-right: 4px; */
  justify-content: end;
}

.tier-name-extra .extra-text {
  color: #ffffff;
  background-color: #323338;
  padding: 6px 8px;
  border-radius: 4px;
  font-weight: 500;
/*   -webkit-flex: 0 0;
  -ms-flex: 0 0; */
 /*  flex: 0 0; */
  word-break:break-all;
 
  
}

.arrow {
  display: inline-block;
  width: 0px;
  height: 0px;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 10px solid;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  border-right-color: #323338;
  position: absolute;
  right: 100%;
}

.btn-purple {
  background-color: #6161FF !important;
}

.btn-blue {
  background-color: rgb(0, 133, 255) !important;
}

.btn-text {
  padding: 16px 32px;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 40px;
  border: none;
  font-weight: 400;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 17px;
  text-transform: none !important;
}

.btn-text-sm {
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 40px;
  border: none;
  font-weight: 400;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 17px;
  text-transform: none !important;
  color: white;
}

.tier-description {
  font-size: 16px;
  /*margin: 20px;*/
}



.price-card-tier-feature svg {
  vertical-align: middle;
  margin-left: 5px;
  margin-bottom: 4px;
}

.pricingPlan {
  min-height: 570px;
}


.priceDiscountOffer {
  text-decoration: line-through;
}

.checkmark {
  color: darkseagreen;
  margin-right: 5px;
}

.buyButton {
  font-size: 18px !important;
  margin-top: 20px;
}

@media (max-width: 768px) {
  
.LY_PricingGrid {
 
  display: flex;
  flex-direction: column;
    
  }
  .LY_PricingGridItem{
    width: 100% !important;
  }
  
.LY_OfferInfoBox{
  width: 100%;
 
}
 
}
