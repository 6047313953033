.LY_DLA_LabelMakerModule_Form_Container{
  display: flex;
  flex-direction: column;
  gap:10px;
}

.LY_DLA_LabelMakerModule_Form_Row{
    display: flex;
    flex-direction: row;
    gap:10px;
   
}
 
.LY_DLA_LabelMakerModule_Form_Inputs{
  flex-direction: column;
  display: flex;
  height: 100%;
  gap:10px;
}