.LY_StaticDropdown_footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    width: 100%;
    height: 25px;
    color: #535964;
    padding: 0 12px;
    background: #FCFCFC;
    border-top: 1px solid #F1F1F1
}

.LY_StaticDropdown_footer>div {
    cursor: pointer;
}