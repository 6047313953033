/* Import font */
/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap&v1');
 */
/* Custom properties */
:root{
    --light-color: #fff;
    --light-color-alt: #515152;
    --primary-background-color: #131417;
    --secondary-background-color: #252830;
    --hover-light-color: var(--light-color);
    --hover-dark-color: var(--primary-background-color);
    --gradient-color: linear-gradient(
        115deg,#4fcf70,#fad648,#a767e5,#12bcfe,#44ce7b);
    --transparent-light-color: rgba(255,255,255,.05);
    --transparent-dark-color: rgba(0,0,0,.75);
    --font-family:  -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;

    --font-size-xsm: 1.2rem;
    --font-size-sm: 1rem;
    --font-size-md: 2.4rem;
    --font-size-lg: 3rem;
    --font-size-xl: 4rem;
    --gap: 2rem;
    --margin-sm: 2rem;
    --margin-md: 3rem;
    --item-min-height-sm: 20rem;
    --item-min-height-md: 30rem;

    --unstable_DataGrid-radius:0 !important;

    --ly-global-primary-text-color: #757575;
    --ly-global-primary-color: #0094CB;
    --ly-global-primary-color2: #007aff;
    --ly-global-background-color: #EBF1F5;  /* rgb(217, 231, 248); #EBF1F5;  EBF1F5 /  E8ECEE   E2E4E5 */
    --ly-global-background-gradient-color: #dce5eb; /* #dce5eb; #b6e2ff #FBF0FF */

    --ly-global-menu-item-background-color:#f7e4fd; /*  #e3ceff; */ 
    --ly-global-menu-item-text-color:#A379BB; /*  #e3ceff; */
 
 /*    --ly-input-active-border-color: var(--mantine-primary-color-filled);  */
    --ly-input-active-border-color: #f7e4fd;/*  rgb(228, 228, 228) ; */
    --ly-input-active-border-arrow-color: #f1d3fa;

    

    --ly-global-scrollbar-track-color: #f1f1f1;
    --ly-global-scrollbar-thumb-color: #d0d0d0;
    --ly-global-scrollbar-thumb-hover-color: #a6a6a6;
    --ly-global-scrollbar-width: 12px;

    --ly-global-modal-box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);

    --ly-input-border-color: rgba(224, 224, 224, 1);

    --ly-global-header-links-text-color: rgb(108, 108, 108);

   
    --ly-global-border-blue: #6fb5ff;
}
*{

  margin: 0;
  padding: 0;
  box-sizing: border-box;

}
/* html{
    font-size: 62.5%;
}
 */
body{
    font-family: var(--font-family) !important;
    font-size: var(--font-size-sm) !important;
    color: var(--light-color-alt) !important;
/*     background-color: var(--ly-global-background-color) !important;   */
    background: linear-gradient(90deg, var(--ly-global-background-color) 0%,var(--ly-global-background-gradient-color) 94%)  !important;
    /* background: rgb(241,241,241);
    background: linear-gradient(126deg, rgba(241,241,241,1) 0%, rgba(170,194,210,1) 94%); */
   /*  background-color: white; */
 
    transition: background-color .25s,color .25s;
    line-height: 1.5;
    font-weight: 450;
}

a{
  color:#1890ff;
  text-decoration: none !important;
}
h1 {
  display: block;
  font-size: 2rem;
/*   margin-block-start: 0.67em; */
/*   margin-block-end: 0.67em; */
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}
.toggleButtons{
  margin:10px !important;
  margin-left: 0 !important;
}
.MuiButton-root{
  border-radius: 0 !important;
} 
.MuiIconButton-root, .MuiPaper-rounded {
  border-radius: 0  !important;
} 
.MouseHand, .MuiLink-root{
  cursor: pointer;
}
.whiteContainer{
  background-color: white;
  padding:20px;
  
}
.alignItemsLeft, .alignItemsLeft span{
  text-align: left !important;
  justify-content: left !important;
  
}
.MuiTab-wrapper{
  text-align: left !important;
  justify-content: left !important;
  align-items: left !important;
}
/* .MuiTextField-root{
  padding: 10px;
} */
/* .MuiOutlinedInput-root {
   border-radius: 0 !important;
} */
/* .MuiOutlinedInput-input {
  padding:20px  !important;
}
.MuiAutocomplete-inputRoot{
  padding-bottom:10px  !important;
} */
.MuiFormHelperText-root{
  font-size: 12px;
}
 
.MuiButton-root{
  border-radius: 0 !important;
}
.TopMenuLinks{
  color: var(--ly-global-header-links-text-color) !important;
 
}

.TopMenuBarUserText{
  text-align: left;
  font-size: 15px;
  line-height: 20px;
  padding-right: 10px;
  color: var(--ly-global-header-links-text-color) !important;

}
.TopMenuBarUserText:hover{
  color: black !important;
}
/* .TopMenuBarMenuPopover{
margin-top:50px;
} */

.TopMenuBarUserButton{
  background-color: transparent !important;
  color: rgb(134, 134, 134) !important;
}

.TopMenuBarUserButton:hover{
  background-color: transparent !important;
  color: black !important;
}
.MuiIconButton-root, .MuiPaper-rounded {
  border-radius: 0  !important;
}
.TopMenuAccountChildMenu{
  width:400px;
  height:250px;
  padding:10px;
}
.TopMenuChildMenu{
  width:400px;
  height:200px;
  padding:10px;
}
.MuiInputBase-root.Mui-disabled{
  color:inherit  !important;
}

.tabTitle{
  margin-left: 15px;
  margin-bottom: 40px;
  margin-top: 20px;
}
.MuiTableCell-head {
line-height: inherit !important;
}
.standardFormInput{
  height: 50px !important;
}
.standardFormInput30{
  height: 30px !important;
  margin-top: 20px;
}

.standardAutocompleteTextField{
  padding-top: 10px !important;
  margin-bottom: 0 !important;
}
.gridAutocompleteTextField{
  padding-top: 20px !important;
  margin-bottom: 10px !important;
}
  
.tableFooterTotals, .tableFooterTotals td {
  font-weight: bold;
  font-size: 14px;
  padding: 5px;
  /* text-align: right; */

}

.auditLogDetailsTableDiv {
  border: 1px solid #dfdede;
}
.auditLogDetailsTableDiv div{
  box-shadow:none !important;
}
.MuiDialog-paperWidthSm {
  max-width: 1000px !important;
}

.ql-tooltip{
  left:5px !important;
}


.MuiListItemIcon-root{
  min-width:2.5rem !important;
}
/* .MuiTab-root{
  min-width: 10vw !important;
}
 */
.MuiTableCell-root, .MuiTableCell-root button{
  padding: 0.5rem !important;
 /*  vertical-align:top  !important; */
 font-size:0.8rem !important;
}
 
.submitLinkBtn{
text-align: right;
font-size: 16px;
font-weight: bold;
margin-top: 10px;
margin-bottom: 10px;
 
}

.formHelperTooltip{
  font-size: 11px;
}
.featuredCandidateStarSpan{
  vertical-align:middle;
  margin-left: 5px;
 
}
.MuiDialog-container{
  margin-top: 35px !important;
}

@media screen and (max-width: 600px) {
  .hideOnMobile {
      display: none;
  }
}

.showOnMobile {
  display: none !important;
}

@media screen and (max-width: 600px) {
  .showOnMobile {
      display: block !important;
  }
}
 
.tableLinkBtn{
  text-align: right;
  font-weight: 500;
  font-size: 16px;
  margin-top: 10px;
}
.MuiMenu-root{
  z-index: 99999 !important;
}
.MuiListItem-padding{
  padding-top:4px !important;
  padding-bottom:4px !important;
}
.MuiListItem-root {
  padding-right: 0px !important;
}
.footer{
  height: 70px;
}
.Mui-disabled {
 -webkit-text-fill-color: initial !important;
}
.navMenuBtn, .navMenuBtn > a{
  text-decoration: none;
  color: var(--ly-global-header-links-text-color) !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  line-height: 1.75 !important;
  letter-spacing: 0.02857em !important;
  text-transform: uppercase;
  min-width: 64px;
  padding: 0px !important;

}
.navMenuBtn, .navMenuBtn > a{
  text-decoration: none;
  color: var(--ly-global-header-links-text-color) !important;
}
.primaryColor{
  color: #2B99FF;
}
.layerHover{
  background-color: #2B99FF;
}
.MuiDataGrid-cell{
  margin:0  !important;
  padding: 0  !important;
 
}
/* .MuiDataGrid-virtualScroller{
  min-height: 50vh !important;
} */
/* ________________________________________ MAINTINE _______________________________________ */

* {
  --mantine-radius-default:0;
  --mantine-scale: 1;
  --mantine-shadow-xl: 0 calc(0.0625rem* var(--mantine-scale)) 
  calc(0.1875rem* var(--mantine-scale)) rgba(0, 0, 0, 0.10), rgba(0, 0, 0, 0.05) 0 
  calc(2.25rem* var(--mantine-scale)) calc(1.75rem* var(--mantine-scale)) 
  calc(-0.4375rem* var(--mantine-scale)), rgb(14 14 14 / 26%) 0 calc(1.0625rem* var(--mantine-scale)) 
  calc(1.0625rem* var(--mantine-scale)) calc(-0.4375rem* var(--mantine-scale));
  
  position: relative;
  margin-top: var(--input-margin-top, 0rem);
  margin-bottom: var(--input-margin-bottom, 0rem);
  --input-height-xs: calc(1.875rem* var(--mantine-scale));
  --input-height-sm: calc(2.25rem* var(--mantine-scale));
  --input-height-md: calc(2.625rem* var(--mantine-scale));
  --input-height-lg: calc(3.125rem* var(--mantine-scale));
  --input-height-xl: calc(3.75rem* var(--mantine-scale));
  --input-padding-y-xs: calc(0.3125rem* var(--mantine-scale));
  --input-padding-y-sm: calc(0.375rem* var(--mantine-scale));
  --input-padding-y-md: calc(0.5rem* var(--mantine-scale));
  --input-padding-y-lg: calc(0.625rem* var(--mantine-scale));
  --input-padding-y-xl: calc(0.8125rem* var(--mantine-scale));
  --input-height: var(--input-height-sm);
  --input-radius: var(--mantine-radius-default);
  --input-cursor: text;
  --input-text-align: left;
  --input-line-height: calc(var(--input-height) - calc(0.125rem* var(--mantine-scale)));
  --input-padding: calc(var(--input-height) / 3);
  --input-padding-inline-start: var(--input-padding);
  --input-padding-inline-end: var(--input-padding);
  --input-placeholder-color: var(--mantine-color-placeholder);
  --input-color: var(--mantine-color-text);
  --input-left-section-size: var(--input-left-section-width, calc(var(--input-height) - calc(0.125rem* var(--mantine-scale))));
  --input-right-section-size: var(--input-right-section-width, calc(var(--input-height) - calc(0.125rem* var(--mantine-scale))));
  --input-size: var(--input-height);
  --section-y: calc(0.0625rem* var(--mantine-scale));
  --left-section-start: calc(0.0625rem* var(--mantine-scale));
  --left-section-border-radius: var(--input-radius) 0 0 var(--input-radius);
  --right-section-end: calc(0.0625rem* var(--mantine-scale));
  --right-section-border-radius: 0 var(--input-radius) var(--input-radius) 0;
  --mantine-cursor-type: pointer;


  --input-bd: var(--mantine-color-gray-4);
  --input-bg: var(--mantine-color-white);
  --input-bd-focus: var(--mantine-primary-color-filled);
  --action-btn-bg: #E7F6F8;
  --switch-cursor: pointer;
}
h2{
  font-size: 1.5rem !important;
  font-weight: 500 !important;
  line-height: 1.5 !important;
  margin-bottom: 15px;
}

/* ________________________________________ MAINTINE END _______________________________________ */
 
/* .MuiDataGrid-main > :last-child {
 display: none !important;
} */

.LY_Loading_Loader, .LY_Loading_Loader div{
background-color: transparent !important;
}

.LY_Global_Modals{
  box-shadow: var(--ly-global-modal-box-shadow) !important;
  --modal-y-offset: 1dvh !important;
 --overlay-z-index: 8888 !important;
}
.Ly_Global_Notifications{
 /*  position: absolute !important; */
 /*  top: -90vh !important; */
/*   right: var(--notifications-right);
  width: var(--notifications-container-width); 
  z-index: var(--notifications-z-index); */
/*   color: white !important; */
z-index: var(--notifications-z-index);
/*   background-color: var(--notification-color, var(--mantine-primary-color-filled));
 */ 
}
/* .Ly_Global_Notifications::before {
  background-color: var(--mantine-color-white);
}
  */
.LY_Global_Notification {
    z-index: var(--notifications-z-index);
}
.LY_ButtonFullWidth{
  padding-inline: 0 !important;
  width: 100% !important;
}

html, body {
  height: 100vh;
  height: 100dvh;
}
.LY_IconButton{
  cursor: pointer;
}

.LY_Flex_Column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.LY_Flex_Row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap:5px;
}

.LY_Scrollbar {
  overflow-y: auto;
 
}

.LY_Scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.LY_Scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

.LY_Scrollbar::-webkit-scrollbar-thumb {
  background: #007aff;
  height: 50px;
  border-radius: 10px !important;
  cursor: pointer;
}

.LY_ScrollbarDropDown {
  overflow-y: auto;
  max-height: 18vh;

 /*  max-height: 170px; */
/*   max-height: 250px; */
}

.LY_ScrollbarDropDown::-webkit-scrollbar {
  width: 8px;
}

.LY_ScrollbarDropDown::-webkit-scrollbar-track {
  background: transparent;
}

.LY_ScrollbarDropDown::-webkit-scrollbar-thumb {
  background: #007aff;
  height: 50px;
  border-radius: 10px !important;
  cursor: pointer;
}


.LY_Scrollbar_Panel::-webkit-scrollbar {
  width: var(--ly-global-scrollbar-width);
}

.LY_Scrollbar_Panel::-webkit-scrollbar-track {
 /*  background: var(--ly-global-scrollbar-track-color); */
  background: #DCE5EB;
}

.LY_Scrollbar_Panel::-webkit-scrollbar-thumb {
  background: var(--ly-global-scrollbar-thumb-color);
  border-top-right-radius: 15px;
  cursor: pointer;
}

.LY_Scrollbar_Panel::-webkit-scrollbar-thumb:hover {
  background: var(--ly-global-scrollbar-thumb-hover-color);
}


 


.LY_PopoverRoundCorners {
  box-shadow: 0px 9px 28px 16px #0000000D !important;
  border-radius: 8px !important;
  padding: 3px;
  border: 1px solid var(--ly-input-active-border-color) !important;

}
.LY_PopoverRoundCorners{
  .mantine-Popover-arrow{
    border-color: var(--ly-input-active-border-arrow-color);
  }
}

.LY_PopoverRoundCorners-arrow {
  width: 12px !important;
  height: 12px !important;
  transform: rotate(45deg);
  position: absolute;
  top: -6.5px !important;
  border-top-left-radius: 0px;
  border-bottom-color: transparent;
  border-right-color: transparent;
  border-color: rgb(227, 227, 227);


}
.LY_OverflowDots{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tiptap-link{
  color: #007aff !important;
}

.mantine-ColorInput-dropdown {
  z-index: 9999 !important;
}
 

.mantine-Popover-dropdown{
  z-index: 8001 !important;
}
 