.LY_ListSettingsModal_Content {
    height: 25vh;
}

.LY_ListSettingsModal_Inputs {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: space-between;
    margin-top: 20px;
}


.LY_ListSettingsModal_Buttons{
    display: flex;
    justify-content: end;
    gap: 10px;
    margin-top: 20px;
}

.LY_ListSettingsModal_InputsName {
    width: 250px;
}


.LY_ListSettingsModal_LoaderContainer {
    height: 25vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.LY_ListSettingsModal_EditFormsLink {
    text-align: end;
    margin-top: 10px;
}