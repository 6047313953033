 
.LY_StatusSelectInput {
  flex: 1;
  display: flex;
  cursor: pointer !important;
}

.LY_StatusSelectInputButton {
  flex: 1;
  display: flex;
  background-color: transparent !important;
  border: none !important;
  cursor: pointer !important;
  color: unset !important;
}

.LY_StatusSelectInputWrapper {
  display: flex !important;
  flex: 1;
}

.LY_StatusSelectInputButtonWrapper {
  flex: 1;
  display: flex !important;
}

.LY_SelectInput:focus {
  border: calc(0.0625rem * var(--mantine-scale)) solid var(--input-bd-focus);
  outline: none;
}

.mantine-Combobox-options {
  z-index: 9900 !important;
}

.LY_StatusSelectMainWrapper {
  cursor: pointer !important;
  font-weight: 500;
}

.LY_StatusSelectRightButton,
.LY_StatusSelectRightButtonWrapper {
  color: unset !important;
}

.LY_StatusSelectOptions {
  color: var(--mantine-color-text);
  font-weight: normal;
}

.LY_StatusDropdown_footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  width: 100%;
  height: 40px;
  color: #535964;
  padding: 0 12px;
  background: #FCFCFC;
  border-top: 1px solid #F1F1F1
}

.LY_StatusDropdown_footer>div {
  cursor: pointer;
}


.LY_StatusSelect_ButtonGroup {
  width: 100% !important;
  display: flex;
  justify-content: flex-end;
  column-gap: 10px;
  border-top: 1px solid #F1F1F1;
  padding: 10px 12px;
}

.LY_StatusDropdown_ButtonGroup_Cancel {
  border: 1px solid #CED4DA !important;
  box-shadow: 0px 2px 0px 0px #00000004 !important;
  color: #535964 !important;
  border-radius: 4px !important;
  cursor: pointer;
}

.LY_StatusDropDdown_ButtonGroup_Save {
  border-radius: 4px !important;
  cursor: pointer;
}

.LY_StatusDropDownDisplayBadge {

  height: 34px !important;
  cursor: pointer !important;

  padding-inline-end: unset !important;
  padding-inline-start: unset !important;
  min-height: unset !important;
  padding: 0px !important;
  margin: 0px !important;

  cursor: pointer !important;
  width: 100% !important;

  padding-inline: 10px !important;
  text-transform: unset !important;
  font-weight: 500 !important;
  min-width: 50px;
  justify-content: center !important;
}




.LY_StatusDropDownPopoverDropdown {
  z-index: 1000 !important;
  padding: 3px !important;
  display: flex;
  flex-direction: column;
  box-shadow: 2px 1px 9px 1px #eeeeee !important;
  padding: 6px;
  border-radius: 8px !important;

}