.LY_StatusDropdown_Options_wrapper {
    display: flex;
    flex-direction: column;
    gap: 1px;
    overflow-y: scroll;
    max-height: 230px !important;
    position: relative;
    padding: 2px 0;
    padding-left: 12px;
    position: relative;
    overflow-x: hidden;

}


.LY_StatusDropdown_Options_item_color_picker {
    width: 22px;
    height: 22px;
    border-radius: 50% !important;
    cursor: pointer;

}

.LY_StatusDropdown_Options_item_input_root {
    width: 100% !important;
}

.LY_StatusDropwdown_Options_item_input_wrapper {
    display: flex;
    width: 100%;
    padding: 1px;
    flex: 1 !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 4px;
    margin: 0 !important;
    margin-bottom: 0px !important;
    bottom: 0px !important;

}

.LY_StatusDropdown_Options_item_left_section_wrapper {
    display: flex;
    flex-direction: row !important;
    position: absolute !important;
    align-items: center !important;
    justify-content: center !important;
    top: unset !important;
    left: 3px !important;
    margin: 0 !important;
    height: 100% !important;
    --input-margin-bottom: 0px !important;

}

.LY_StatusDropdown_Options_item_left_section {
    display: flex;
    flex-direction: row !important;
    gap: 5px;
    padding-left: 20px;
    align-items: center !important;
    justify-content: center !important;
    height: 100% !important;
}

.LY_StatusDropdown_Options_item_input {
    display: flex;
    flex: 1 !important;
    padding: 0 !important;
    margin: 0 !important;
    padding-left: 60px !important;
    border-radius: 8px !important;
    font-size: 12px !important;

}

.LY_StatusDropdown_Options_item_picker_input_wrapper {
    margin: 10px 0 !important;
    width: 100%;
}

.LY_StatusDropdown_Options_item_picker_input {
    border-radius: 8px !important;
    border: 1px solid #CED4DA !important;
}

.LY_StatusDropdown_Options_item_advancedInput_wrapper {
    width: 100%;
    width: 200px;
    min-height: 25px;
    height: 25px;
}

.LY_StatusDropdown_Options_item_advancedInput {
    border: 1px solid #CED4DA !important;
    border-radius: 8px !important;
    padding: 2px;
    min-height: 30px;
    height: 30px;
}

.LY_StatusDropdown_Options_add_option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 12px;
    margin-bottom: 10px;
}

.LY_StatusDropdown_Options_add_option_button {
    border: 1px solid #A7AAB0 !important;
    border-radius: 8px !important;
    /*   padding: 5px !important; */
}

.LY_StatusDropdown_Options_add_option_button_inner {
    color: #535964 !important;

}

.LY_StatusDropdown_Options_switch_track_label {
    font-size: 14px !important;
}

.LY_StatusDropdown_Options_switch_body {
    display: flex;
    align-items: center;
    justify-content: center;
}


.LY_StatusDropdown_Options_input {
    border-radius: 8px !important;
    padding-left: 30px !important;
}

.LY_StatusDropdown_Options_input_wrapper {
    margin: 0 12px;
}

.LY_StatusDropdown_Option_sortable_item {
    display: flex;
    align-items: flex-start;
    background-color: #fff;
    width: 100%;
    border-radius: 8px;
    column-gap: 5px;

}

.LY_StatusDropdown_Option_sortable_item:hover {
    background-color: #f9f9f9;
}

.LY_StatusDropdown_Option_sortable_item-handle {
    cursor: grab;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 6px;
}

.LY_StatusDropdown_Options_item_delete {
    padding-top: 5px !important;
    cursor: pointer;
}
/* .LY_StatusDropdown_Options_item_delete_div{
    padding-right:3px;
} */

.LY_StatusDropdown_Options_item_picker_saturation {
    height: 70px !important;
}

.LY_StatusSelect_Options_Modal_Footer {
    margin-top: 20px;
    width: 100%;
    display: flex;
    column-gap: 10px;
    justify-content: flex-end;
}