 
.LeftMenuDrawer{
    width: 280px;
    flex-shrink: 0;
}

.LeftMenuDrawerPaper {
     width: 230px; 
   
}  
.drawerContainer{
    overflow: hidden;
}
.LeftMenuList{
    padding-top: 0;
}