.CopyListScreenForm{
  display: flex;
  flex-direction: column;
/*   gap: 1rem; */
  height: auto;
/*   max-height: 450px;
   overflow: auto;  */
 font-size: inherit !important;
}
  .CopyListScreenFormScrollArea {
    display: flex;
    flex-direction: column;
    gap: .5rem;

/*   gap: 10px;
  display: grid;
  grid-template-columns: 1fr;
  overflow: auto; */
 
}  
 

/* ResponsiveModal.css */
.CopyListScreenFormModal {
  max-height: 60vh; /* Default max height for large screens */
}
 

@media (max-width: 768px) {
  .CopyListScreenFormModal {
    max-height: 80vh; /* For small screens */
  }
}
