.LY_ListFilterModule_Search {
  position: relative;
  max-width: 380px;
  width: 100%;
}
@media screen and (max-width: 768px) {
  .LY_ListFilterModule_Search_active_Mobile {
    position: absolute;
    width: 250px !important;
    z-index: 999;
  }
}
.LY_ListFilterModule_Search_adjustmentsIcon {
  width: 28px;
  height: 28px;
  position: absolute;
  top: 50%;
  right: 40px;
  transform: translateY(-50%);
  stroke: 1.5;
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .LY_ListFilterModule_Search_adjustmentsIcon {
    position: relative;
    width: 36px !important;
    height: 36px !important;
    box-shadow: 0px 0px 4px 0px #00000026;
    padding: 6px;
    border-radius: 4px;
    top: unset;
    right: unset;
    transform: unset;
  }
  .LY_ListFilterModule_Search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: unset;
    column-gap: 8px;
  }
  .LY_ListFilterModule_Filter_Header_Wrapper {
    width: 200px !important;
  }
}
.LY_ListFilterModule_Search_adjustmentsIcon:hover {
  background: #f1f1f1;
  border-radius: 2px;
}

.LY_ListFilterModule_Search_comboboxDropdown {
  width: 380px;
}

.LY_ListFilterModule_Filter_Searchbar_SearchIcon {
  width: 100%;
  height: 100%;
  padding: 8px;
}

.LY_ListFilterModule_Search_columnsHeader {
  padding: 3px 12px;
  line-height: 18px;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
}

.LY_ListFilterModule_Search_optionItem {
  height: 46px;
  display: flex;
  column-gap: 8px;
  align-items: center;
}

.LY_ListFilterModule_Search_inputDropdownWrapper {
  margin: 16px;
}

.LY_ListFilterModule_Search_inputDropdownField {
  padding-left: 40px;
  border: 1px solid #757b87;
  border-radius: 5px;
}

.LY_ListFilterModule_Search_emptyOption {
  padding: 3px 12px;
  font-size: 12px;
  color: #a7aab0;
}
.LY_ListFilterModule_Columns_optionItem {
  height: 38px;
  display: flex;
  column-gap: 8px;
}

.LY_ListFilterModule_Columns_optionItem_root {
  display: flex;
  align-items: center;
}
.LY_ListFilterModule_Columns_optionItem_label {
  width: 100%;
  height: 100%;
  display: flex;
}

.LY_ListFilterModule_Columns_optionItem_labelWrapper {
  width: 100%;
}
.LY_ListFilterModule_Filter_Header_Searchbar_Root {
  margin: 6px 12px 10px 12px;
}

.LY_ListFilterModule_Filter_Header_Searchbar_Input {
  padding-left: 38px !important;
  border-radius: 8px !important;
  border: 1px solid var(--ly-input-border-color) !important;
}
.LY_ListFilterModule_Filter_Header_Input {
  border-radius: 8px !important;
  border: 1px solid var(--ly-input-border-color) !important;
}
.LY_ListFilterModule_Columns_options {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 230px;
}

.LY_ListFilterModule_Columns_options::-webkit-scrollbar {
  width: 8px;
}
.LY_ListFilterModule_Columns_options::-webkit-scrollbar-track {
  background: transparent;
}
.LY_ListFilterModule_Columns_options::-webkit-scrollbar-thumb {
  background: #007aff;
  height: 50px;
  border-radius: 10px !important;
  cursor: pointer;
}
.LY_ListFilterModule_Filter_Searchbar_Handler {
  width: 28px;
  height: 28px;
  position: absolute;
  top: 50%;
  right: 40px;
  transform: translateY(-50%);
  stroke: 1.5;
  cursor: pointer;
}
.LY_ListFilterModule_Filter_Searchbar_SearchIcon_Mobile {
  padding: 8px;
  box-shadow: 0px 0px 8px 0px #8c8c8c45;
  border-radius: 4px;
}
.LY_ListFilterModule_Filter_Searchbar_Icon {
  border-left: 0.5px solid var(--ly-input-border-color);
  cursor: pointer;
}
