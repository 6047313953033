 
.LY_SwitchThumb {
  transition: unset !important;
  transition-duration: 0ms !important;
}
.LY_SwitchInputContainer{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.LY_SwitchInputContainerForm{
  display: flex;
  align-items: left;
  justify-content: left;
  /* width: 100%; */
  height: 100%;
}


/* .LY_SwitchTrack, .LY_SwitchThumb{
  cursor: pointer;
}
.LY_SwitchInput {
 
  cursor: pointer; 
 
} */

/* 

.LY_SwitchInput_focus:focus {
  border-color: var(--input-bd-focus);
 }
.LY_SwitchInput_error {
  border-color: var(--ly-input-border-error-color);
 
}
.LY_Input_ReadOnly{
  min-height: 1.5rem;
} */