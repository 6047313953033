.LY_TimeEstimate {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.LY_TimeEstimate {
  width: 100% !important;
}

.LY_TimeEstimate_InputContainer {
  width: 100% !important;
}

.LY_TimeEstimate_IconHelp {
  cursor: pointer;
}
