.LY_DLA_LabelMakerModule_Container{
    display: flex;
    flex-direction: row;
    gap:10px;
    height: 40vh;
    width:10in;
}
.LY_DLA_LabelMakerModule_ContainerTabPanel{
    padding-top: 10px;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    border-bottom: 1px solid #d8d8d8;
    padding-bottom: 5px;
}
.LY_DLA_LabelMakerModule_Container_Buttons{
    display: flex;
    flex-direction: row;
    gap:10px;
    justify-content: flex-end;
}
.LY_DLA_LabelMakerModule_ContainerBase{
    display: flex;
    flex-direction: column;
    gap:5px;
    width: 100%;
}