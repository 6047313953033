.LY_LinkSelector_PopoverDropdown{
    margin-top: 40px;
}
.LY_LinkSelector_ButtonText {
    text-decoration: underline;
}

.LY_LinkSelector_DropdownForm {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px;
    gap: 8px;
}