

.ly-main-top-nav{

  box-shadow: none !important;
/*   background-color: var(--ly-global-background-color) !important;
 */  
 background: linear-gradient(90deg, var(--ly-global-background-color) 0%,var(--ly-global-background-gradient-color) 94%)  !important;

/*  background-color: transparent !important; */

  height: 54px;
}