.LY_RecordUpdateModule_Form{
   display: flex;
  flex-direction: column; 
  gap:.5rem;
  padding-bottom: 1rem;


  width: 100%;
}


.LY_RecordUpdateModule_FormContainer{
  display: grid !important;
/*    grid-template-columns: 1fr 1fr 1fr  !important; */
  grid-template-columns: repeat(auto-fit, minmax(16vw, 1fr));
  grid-auto-flow: row;
/*    grid-auto-flow: column !important; */
  grid-gap: 10px;
 /*  max-height: 200px;   */
  height: 100%;
  overflow: auto;
  padding-right: 5px;
  
}

.LY_RecordUpdateModule_FormContainerItem {
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;

 /*  padding-top:5px; */
/*     border: 1px solid #e0e0e0; */


}


.LY_RecordUpdateModule_Form_GridLayout {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Adjust number of columns */
  grid-auto-rows: 100px; /* Adjust height of rows */
  gap: 10px; /* Adjust spacing between grid items */
}

.LY_RecordUpdateModule_Form_GridLayoutItem{
  background-color: rgb(158, 158, 158);
}

@media (max-width: 600px) {
  .LY_RecordUpdateModule_FormContainer {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    max-height: 400px;
  }
}


.LY_RecordUpdateModule_Form_GridLayout2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);  
 
  gap: 10px; /* Adjust spacing between grid items */
  grid-template-rows: auto; /* Automatic height based on content */

}

.LY_RecordUpdateModule_Form_GridLayout2 > .grid-item-a {
  grid-column: 1 / span 1; /* Spans across 2 columns */

}

.LY_RecordUpdateModule_Form_GridLayout2 > .grid-item-b {
  grid-column: 1 / span 2; /* Spans across 2 columns */

}

.LY_RecordUpdateModule_Form_GridLayout2 > .grid-item-c {
  grid-column: 2 / span 1; /* Spans across 3 columns */
  min-height: 180px; /* Minimum height for item D */
}

.LY_RecordUpdateModule_Form_GridLayout2 > .grid-item-d {
  grid-column: 1 / span 2; /* Spans across 1 column */

}

