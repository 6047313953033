.LY_ListFiltersModule_Filter_footer {
  display: flex;
  align-items: center;
  gap: 10px 16px;
  padding: 10px 16px;
  justify-content: space-between;
}

.LY_ListFiltersModule_Filter_button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fcfcfc;
  padding: 10px;
  color: #535964 !important;
  border:1px solid #e0e0e0 !important;
  border-radius: 4px !important;
}

.LY_ListFiltersModule_Filter_footer_buttons {
  display: flex;
  align-items: center;
  column-gap: 10px;
  padding: 0 16px;
  justify-content: space-between;
}

.LY_ListFiltersModule_Filter_footer_actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.LY_ListFiltersModule_Filter_footer_actions>button {
  color: #535964;
  border-radius: 4px;
}

.LY_ListFiltersModule_Filter_footer_action_button {
  width: 40px !important;
  height: 40px !important;
  box-shadow: 0px 0px 4px 0px #00000026;
  background: #ffffff;
}

.LY_ListFiltersModule_Filter_Item {
  padding: 5px !important;
  height: 40px !important;
  text-align: center !important;
}

@media screen and (max-width: 768px) {
  .LY_ListFiltersModule_Filter_footer_dropdown {
    z-index: 9999 !important;
  }
}