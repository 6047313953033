 
.LY_ButtonPinkHover {
    padding:5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
}

.LY_ButtonPinkHover:hover, .LY_ButtonPinkHover_active {
    background-color: var(--ly-global-menu-item-background-color) !important;
    color: var(--ly-global-menu-item-text-color) !important;
 
}
.LY_ButtonPinkHover_Dropdown{
  
    left: 0;
    top:50px;
    margin-top:30px;
}