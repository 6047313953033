.LY_NewWorkspaceModule_Selected_Blank {
    border: 1px solid #CED4DA;
    width: 100%;
    height: 150px;
    border-radius: 15px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.LY_NewWorkspaceModule_Selected {
    box-shadow: 0 0 0 2px #007AFF !important;
}
