.MainLayoutContent {
    flex-grow: 1;
    /*  padding:  20px; */
    /* margin-left: 250px; */
    /*  margin-top:53px; */
    overflow: hidden;
    /*  background-color: white; */

    height: 100vh;
    min-height: 100vh;

    padding-top: 53px;
}
.MainLayoutContentScroll{
    height: unset;
/*     min-height: unset;
    overflow: auto; */
}


.MainPageContentCenter {
    flex-direction: column;
    display: flex;
    justify-content: center;  
    align-items: center;  
    height: 100%;
    
}
.MainPageContent {
    /*  padding-top: 53px; */
     min-height: 70vh;
 }

.MainPageContentTop {
    justify-content: start; 
    margin-top: 10vh;
    height: 100%;
}