.LY_SlashEditor_Action_List_ButtonText{
    padding: 2px 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 13px;
    color: #11161F;
}

.LY_SlashEditor_Action_List_Dropdown {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.LY_SlashEditor_Action_List_DropDownItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 7px;
    cursor: pointer;
    padding: 4px;
}

.LY_SlashEditor_Action_List_DropDownItem:hover {
background-color: #F8F9FA;
}


.LY_SlashEditor_Action_List_DropDownItemContent {
    display: flex;
    align-items: center;
    gap: 4px;
}