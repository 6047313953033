.LY_ColorSelector_Button {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 56px;

}

.LY_ColorSelector_ButtonText {
    width: 10px;
    text-align: center;
}

.LY_ColorSelector_Dropdown {
    display: flex;
    flex-direction: column;
}

.LY_ColorSelector_DropdownHeader {
    margin: 4px 0;
    padding: 4px;
    font-size: 14px;
    font-weight: 600;
}

.LY_ColorSelector_DropdownItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px;
    cursor: pointer;
    font-size: 14px;
}

.LY_ColorSelector_DropdownItem:hover {
    background-color: #F8F9FA;
}

.LY_ColorSelector_DropdownItemContent {
    display: flex;
    align-items: center;
    gap: 8px;
}

.LY_ColorSelector_DropdownColorBox {
    padding: 2px 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-weight: 500;
}

.LY_SlashEditor_Toolbar_Icons{
    display: flex;
    align-items: center;
    justify-content: space-between;
}