 

.LY_StaticSelectInput {
  flex: 1;
  display: flex;
  cursor: pointer !important;
}

.LY_StaticSelectInputButton {
  flex: 1;
  display: flex;
  background-color: transparent !important;
  border: none !important;
  cursor: pointer !important;
  color: unset !important;
}

.LY_StaticSelectInputWrapper {
  display: flex !important;
  flex: 1;
}

.LY_StaticSelectInputButtonWrapper {
  flex: 1;
  display: flex !important;
}

.LY_SelectInput:focus {
  border: calc(0.0625rem * var(--mantine-scale)) solid var(--input-bd-focus);
  outline: none;
}

.mantine-Combobox-options {
  z-index: 9900 !important;
}

.LY_StaticSelectMainWrapper {
  cursor: pointer !important;
  font-weight: 500;
}

.LY_StaticSelectRightButton,
.LY_StaticSelectRightButtonWrapper {
  color: unset !important;
}

.LY_StaticSelectOptions {
  color: var(--mantine-color-text);
  font-weight: normal;
}

.LY_StaticSelect_footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  width: 100%;
  height: 40px;
  color: #535964;
  padding: 0 12px;
  background: #FCFCFC;
  border-top: 1px solid #F1F1F1
}

.LY_StaticSelect_footer>div {
  cursor: pointer;
}

.LY_StaticSettings_tab_section {
  width: 50%;
}

.LY_StaticSelect_ButtonGroup {
  width: 100% !important;
  display: flex;
  justify-content: flex-end;
  column-gap: 10px;
  border-top: 1px solid #F1F1F1;
  padding: 5px 12px;
}

.LY_StaticSelect_ButtonGroup_Cancel {
  border: 1px solid #CED4DA !important;
  box-shadow: 0px 2px 0px 0px #00000004 !important;
  color: #535964 !important;
  border-radius: 4px !important;
  cursor: pointer;
}

.LY_StaticSelect_ButtonGroup_Save {
  border-radius: 4px !important;
  cursor: pointer;
}

.LY_StaticDropDownDisplayBadgeView {
  cursor: pointer !important;
  display: flex;
  align-items: center !important;
  line-height: unset !important;
  justify-content: flex-start;
/*   padding-inline-end: unset !important;
  padding-inline-start: unset !important; */
  border: unset !important;
  min-height: 22px !important;
 /*  padding: 0px !important; */
  margin: 0px !important;
  text-align: left !important;
  cursor: pointer !important;
/*    padding-inline: 1px;  */ 
  text-transform: unset !important;
/*   font-weight:unset !important; */
/*   font-size: unset !important; */
}

.LY_StaticDropDownDisplayBadge {
  cursor: pointer !important;
  display: flex;
  align-items: center;
  justify-content: flex-start;
/*   font-size: 12px !important; */
  padding-inline-end: unset !important;
  padding-inline-start: unset !important;
  border: unset !important;
  min-height: 20px !important;
  padding: 12px !important; 
  margin: 0px !important;
  text-align: left !important;
  cursor: pointer !important;
  width: 100% !important;
  padding-inline: 10px !important;
  text-transform: unset !important;
  font-weight:unset !important;
}




.LY_StaticDropDownPopoverDropdown {
  min-width: 200px;
  padding: 3px !important;
  display: flex;
  z-index: 50;
  flex-direction: column;
  box-shadow: 2px 1px 9px 1px #eeeeee !important;
  padding: 6px;
  border-radius: 8px !important;

}



.LY_Static_List_Renderer {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  align-items: center;
  max-height: 125px;
  width: 100%;
  padding-block: 5px;
}