.LY_ListFilterModule {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  column-gap: 12px;
  padding-right: 20px;
  margin-bottom: 10px;
  min-width: 55vw;
}

.LY_ListFiltersModule_FiltersWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 12px;
  row-gap: 12px;
}

@media screen and (max-width: 768px) {
  .LY_ListFiltersModule_FiltersWrapper {
    column-gap: 8px;
    width: unset !important;
  }

  .LY_ListFilterModule {
    padding: 2px;
    padding-right: 10px;
    
  }

  .LY_ListFiltersModule_FiltersWrapper_Filters {
    width: unset !important;
  }
}

.LY_ListFiltersModule_FiltersWrapper_Filters {
  display: flex;
  align-items: center;
  column-gap: 10px;
  width: 100%;
}

.LY_ListFiltersModule_Filter_button_active {
  background-color: #efefef !important;
  border-radius: 4px !important;
}

.LY_ListFiltersModule_Filter_count {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  color: #007aff;
  border-radius: 50%;
  background-color: #007aff26;
  margin-left: 3px;
}