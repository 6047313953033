.LY_EmailAccountSetup_Modal_Body {
    /* padding: 0 !important;
    height: 80vh !important; */
    display: flex !important;
    flex-direction: column !important;
}

.LY_EmailAccountSetup_Modal_Content{
    min-width: 600px !important;
    min-height: 400px !important;
}

.LY_EmailAccountSetup_Modal_Header{
    padding: 16px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 700;
    background-color: rgba(252, 252, 252, 1);
    box-shadow: 0px -1px 0px 0px rgba(240, 240, 240, 1) inset;
    color: #262626;
}

.LY_EmailAccountSetup_Step_AccountTypes_Container{
    padding: 0 24px;   
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 100%;
}

.LY_EmailAccountSetup_Step_Root{
    display: flex !important;
    flex-direction: column !important;
    height: 100% !important;
}

.LY_EmailAccountSetup_Step_Content{
    height: 100% !important;
    display: flex !important;
    flex-direction: column !important;
    padding:10px !important;
    padding-top: 0 !important;

}

.LY_EmailAccountSetup_Steps_Container {
    padding-right: 10px;
    padding-bottom: 10px;
}

.LY_EmailAccountSetup_Step_Label_Active{
    color: #262626;
    font-size: 24px !important;
    font-weight: 500 !important;
}

.LY_EmailAccountSetup_Step_Label{
    color: rgba(140, 140, 140, 0.6);
    font-size: 24px !important;
    font-weight: 400 !important;
}

.LY_EmailAccountSetup_Step_Separator {
    background-color: rgba(240, 240, 240, 1) !important;
}

.LY_EmailAccountSetup_Step_Icon[data-progress] {
        background-color: rgba(24, 144, 255, 1)  !important;
        color: white !important;
        border: none !important;
}

.LY_EmailAccountSetup_Step_Icon {
    background-color: transparent  !important;
    color: rgba(217, 217, 217, 1) !important;
    border: 1px solid rgba(217, 217, 217, 1) !important;
} 

.LY_EmailAccountSetup_Step_Icon_Completed{
    background-color: white !important;
    color: rgba(24, 144, 255, 1) !important;
    border: 1px solid rgba(24, 144, 255, 1) !important;
    border-radius: 50% !important;
}

.LY_EmailAccountSetup_Modal_Content_Body{
    flex: 1;
}

.LY_EmailAccountSetup_Modal_Content_Footer{
    padding: 16px 24px;
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    background-color: rgba(252, 252, 252, 1);
    box-shadow: 0px 1px 0px 0px rgba(240, 240, 240, 1) inset;
}

.LY_EmailAccountSetup_Stepper_Steps{
    padding: 16px;
}

.LY_EmailAccountSetup_Loader {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 1199px) {

    .LY_EmailAccountSetup_Modal_Content{
    max-width: 700px !important;
     
    }

}
