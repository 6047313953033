* {
 

  --ly-dropdown-selected-item-bg-color: var(--ly-global-menu-item-background-color);   
  --ly-dropdown-selected-item-text-color: var(--ly-global-menu-item-text-color);   
}

.LY_DropDownContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 10px;
  gap: .6rem;
  cursor: pointer;
  border: 1px solid var(--ly-input-border-color) !important;
}
.LY_DropDownContainer:hover {
   .LY_DropDownActions {
    display: flex !important;
 
  }

}

.LY_DropDownActions {

    display: none;
  /*   display: flex; */
/*   display: flex; */
  justify-content: center;
  align-items: center;
  gap: 5px;

}
.LY_DropDownActions:hover {
  display: flex;

}


.LY_DropDownActionsButton{
  display: flex;
  justify-content: center;
  padding: 3px;
  color: var(--mantine-primary-color-filled);
  cursor: pointer;
}

.LY_DropDownActionsButton:hover{
  background-color: var(--action-btn-bg);
  color: var(--mantine-primary);

}

.LY_DropDownLink{
  /* color: var(--mantine-color-black) !important; */
  text-decoration: none;
}

.LY_DropDownOptionActive{
  background-color: var(--ly-dropdown-selected-item-bg-color) !important;
  color: var(--ly-dropdown-selected-item-text-color) !important;
  border-radius: 10px !important;
  padding-block: 3px !important;
 
}