.LY_StaticSelect_Styles_Wrapper {
    padding: 8px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    row-gap: 10px;
}

.LY_StaticSelect_Styles_select_input {
    border-radius: 8px !important;
    border: 1px solid #CED4DA !important;
}


.LY_StaticSelect_Styles_Button {
    /*  height: 100px; */
    display: flex;
    justify-content: center;
    align-items: center
}