.LY_ListFiltersModule_Sort_button {
  color: #535964 !important;
}

.LY_ListFiltersModule_Sort_comboboxDropdown {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .LY_ListFiltersModule_Sort_comboboxDropdown {
    width: 100%;
  }
}


.LY_ListFiltersModule_Sort_options {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 200px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.LY_ListFiltersModule_Sort_optionItem_selects {
  display: flex;
  justify-content: space-between;
  align-items: start;
  flex-wrap: wrap;
  gap: 10px;
  flex: 1 1;
}

@media screen and (max-width: 768px) {
  .LY_ListFiltersModule_Sort_optionItem_selects {
    flex-direction: column;
  }
}

.LY_ListFiltersModule_Sort_optionItem {
  display: flex;
  align-items: center;
  gap: 10px 16px;
  padding: 0 16px;
  justify-content: space-between;
}

.LY_ListFiltersModule_Sort_optionItem:hover {
  background-color: #ebf1f5;
}

@media screen and (max-width: 768px) {
  .LY_ListFiltersModule_Sort_optionItem {
    padding: 0;
  }
}



.LY_ListFiltersModule_Sort_Apply_button {
  background-color: #007aff;
  color: white;
  border-radius: 4px !important;
}

.LY_ListFiltersModule_Sort_Cancel_button {
  border-radius: 4px !important;
  color: #262626 !important;
  border: 1px solid #a7aab0 !important;
}

.LY_ListFiltersModule_Sort_header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  column-gap: 12px;
}

.LY_ListFiltersModule_Sort_footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fcfcfc;
  padding: 10px;
}


.LY_ListFiltersModule_Sort_Select_Columns {
  flex: 2;
}

.LY_ListFiltersModule_Sort_footer_buttons {
  display: flex;
  align-items: center;
  gap: 10px 16px;
  padding: 0 16px;
  justify-content: space-between;
}

.LY_ListFiltersModule_Sort_button_Mobile {
  box-shadow: 0px 0px 4px 0px #00000026;
  padding: 6px;
  border-radius: 4px;
}

.LY_ListFiltersModule_Sort_optionItem_then {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.LY_ListFiltersModule_Sort_Select_Sort_Direction {
  flex: 1;
}

.LY_ListFiltersModule_Sort_Select_Sort_Direction_Input {
  height: 38px !important;
  cursor: pointer;
}