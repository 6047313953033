/* LY_InputBase.css */
.label {
    font-size: 16px;
    font-weight: bold;
  }
  
  .error {
    color: red;
    font-size: 14px;
  }
   

 .LY_InputBaseLabel {
    display: inline-block;
    font-weight: 500;
    word-break: break-word;
    cursor: default;
    -webkit-tap-highlight-color: transparent;
    font-size: var(--input-label-size, var(--mantine-font-size-sm));
    text-wrap:nowrap;
}
.LY_InputBaseLabelStar {

  color: var(--input-asterisk-color, var(--mantine-color-error));
 
}
.LY_InputBaseHelpText {
    color: var(--mantine-color-dimmed);
    font-size: var(--input-description-size, calc(var(--mantine-font-size-sm) - calc(0.125rem* var(--mantine-scale))));
}

