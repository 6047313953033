* {
  --ly-svg-arrow-down: "M0.983887 1.33854L1.79589 0.5L5.98438 4.82322L10.1718 0.500052L10.9839 1.33849L5.98448 6.5L0.983887 1.33854Z";

  /*   --ly-tree-menu-item-bg-hover-color: #f0f0f0;
 */
  --ly-tree-menu-item-bg-hover-color: var(--ly-global-menu-item-background-color);
  /* #f7e4fd  #e3ceff; */
  --ly-tree-menu-item-text-hover-color: var(--ly-global-menu-item-text-color);
  /* #A379BB;  #e3ceff; */

}

.LY_TreeMenu {

  margin: 0;
  padding: 0;

}

.LY_TreeMenuList {
  margin: 0;
  padding: 0;
  list-style: none;
  /* padding: 20px; */
  line-height: 2rem;
}


.LY_TreeMenuList summary {
  cursor: pointer;
}

.LY_TreeMenuList summary::-webkit-details-marker,
.LY_TreeMenuList summary::marker {
  display: none;
}

.LY_TreeMenuList summary {
  list-style-type: none;
}

.LY_TreeMenuList li ul {
  padding-left: 0.1;
  /* 1rem; */

}

.LY_TreeMenuList li,
.LY_TreeMenuSubMenu summary {
  padding-left: 0.5rem !important;
  padding-left: 0rem !important;


  margin-block: .2rem !important;

}


.LY_TreeMenuSubMenuSummary {
  display: flex;
  align-items: center;


}

.LY_TreeMenuSubMenuSummaryTitle {
  flex: 1;
}

.LY_TreeMenuSubMenuSummary::before {
  --ly-svg-arrow-down: "M0.983887 1.33854L1.79589 0.5L5.98438 4.82322L10.1718 0.500052L10.9839 1.33849L5.98448 6.5L0.983887 1.33854Z";
  /*   content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 50 50'%3E%3Cpath d='var(--ly-svg-arrow-down)' stroke='%23000000' stroke-width='4' fill='none' /%3E%3C/svg%3E");
 */
  content: '';
  width: 15px;
  height: 15px;
  background-color: black;
  clip-path: path(var(--ly-svg-arrow-down));
  transform: translateY(-15%) rotate(-90deg);
  transition: transform 0.3s;
  position: relative;
  left: .2rem;
  top: .2rem;
}

.LY_TreeMenuSubMenuDetails {
  margin-left: 1rem;

}

.LY_TreeMenuSubMenuDetails[open]>.LY_TreeMenuSubMenuSummary::before {
  transform: translateY(15%) rotate(0deg);

}

.LY_TreeMenuItemContextMenuBtn {

  cursor: pointer;
  border: none;
  background-color: transparent;

  max-height: 14px;
  display: none;
  margin-right: 5px;
  border-radius: 5px;
  padding: 3px;

  text-align: center;


}

.LY_TreeMenuItemContextMenuBtn:hover {
  background-color: rgb(194, 194, 194);
  color: black;


}

.LY_TreeMenuItemDisplayInfo:hover .LY_TreeMenuItemContextMenuBtn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.LY_TreeMenuItemDisplayInfo {
  cursor: pointer;
  padding-left: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 2.2rem;
  width: auto;
  flex: 1;

}



.LY_TreeMenuItemDisplayInfo:hover,

.LY_TreeMenuSelectedItem {
  background-color: var(--ly-tree-menu-item-bg-hover-color);
  border-radius: 8px;
  /* color: var(--ly-tree-menu-item-text-hover-color); 
  font-weight: 500;*/
}

.LY_TreeMenuSelectedSubMenu,  
.LY_TreeMenuSelectedSubMenu:hover {

  .LY_TreeMenuSubMenuSummary{
    background-color: var(--ly-tree-menu-item-bg-hover-color);
    border-radius: 8px;
  }
}

.LY_TreeMenuSelectedItem .LY_TreeMenuItemContextMenuBtn {

  display: flex;
  align-items: center;
  justify-content: center;
}

.LY_TreeMenuSelectedSubMenu:first-child li {
  background-color: var(--ly-tree-menu-item-bg-hover-color);
}

.LY_TreeMenuItemPreIcon {
  display: flex;
  margin-right: 1rem;

}

.LY_TreeMenuItemDisplayInfoContent {
  display: flex;
  align-items: center;
  width: 100%;

}