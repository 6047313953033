 
.LY_WebsiteInputContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 10px;
  gap: .6rem;
  cursor: pointer;

}
.LY_WebsiteInputContainer:hover {
   .LY_WebsiteInputActions {
    display: flex !important;
 
  }

}

.LY_WebsiteInputActions {

  position: absolute;
    display: none;
  /*   display: flex; */
/*   display: flex; */
  justify-content: center;
  align-items: center;
  gap: 5px;

}
.LY_WebsiteInputActions:hover {
  display: flex;

}


.LY_WebsiteInputActionsButton{
  display: flex;
  justify-content: center;
  padding: 3px;
  color: var(--mantine-primary-color-filled);
  cursor: pointer;
/*   background-color: #F5F5F5; */
}

.LY_WebsiteInputActionsButton:hover{
  background-color: var(--action-btn-bg);
  color: var(--mantine-primary);

}

.LY_WebsiteInputLink{
  /* color: var(--mantine-color-black) !important; */
  text-decoration: none;

  overflow: hidden;
  text-overflow: ellipsis;
  min-height:var(--input-height);
  display: flex;
  align-items: center;
}
