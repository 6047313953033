.LY_UsersPopup_Header {
    display: flex;
    align-items: center;

}

.LY_UsersPopup {
    z-index: 9999;
    background-color: #fff;
    border-radius: 5px;
    padding: 5px;
}

.LY_UsersPopup_ScrollArea {
    max-height: 230px;
    overflow-y: auto;
}

.LY_UsersPopup_ScrollArea::-webkit-scrollbar {
    width: 10px; /* Width of the scrollbar */
  }
  
  .LY_UsersPopup_ScrollArea::-webkit-scrollbar-track {
    background: transparent; /* Remove the track background */
  }
  
  .LY_UsersPopup_ScrollArea::-webkit-scrollbar-thumb {
    background-color: #4DABF7; /* Blue scroll thumb */
    border-radius: 10px; /* Rounded thumb */
    border: 2px solid transparent; /* Create space around the thumb */
    cursor: pointer;
  }
  
  .LY_UsersPopup_ScrollArea::-webkit-scrollbar-thumb:hover {
    background-color: #339af0; /* Darker blue on hover */
   
  }
  
  /* Remove scrollbar arrows */
  .LY_UsersPopup_ScrollArea::-webkit-scrollbar-button:increment {
    width: 0px;
  }
  /* For Firefox */
  .LY_UsersPopup_ScrollArea {
    scrollbar-width: thin;
    scrollbar-color: #4DABF7 transparent; /* Blue thumb, transparent background */
  }

  .LY_UsersPopup_Option:hover {
    background-color: #F8F9FA;
    cursor: pointer;
  }