.LY_ComposeEmail_UserEmail_DropDown_Selected{
    display: flex;
    align-items: center;
    gap: 5px;
    border: 1px solid #CED4DA;
    border-radius: 4px;
    padding: 5px;
}

.LY_ComposeEmail_DropDown_Input_Container{
    border: none ;
}

.LY_ComposeEMail_UserDD_Footer_Container {
    padding: 5px 12px;
    border-top: 1px solid #757B87;
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;
    color: #535964;
    font-weight: 400;
    cursor: pointer;
}