
.LY_FileImportModule_Modal_Table_DropdownFooter {
    border-top: 1px solid #A7AAB0;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 5px;
    gap: 10px;
    cursor: pointer;
}

.LY_FileImportModule_Modal_Table_TableWrapper {
    height: 50vh;
    overflow-y: scroll;
    border: 1px solid #F2F2F2;
}

.LY_FileImportModule_Modal_Table {
    height: 70vh;
    overflow: scroll;
}

.LY_FileImportModule_Modal_Table_Body {
    height: 50vh;
    overflow-y: scroll;
}

.LY_FileImportModule_Modal_Table_Footer{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
}

.LY_FileImportModule_Modal_Table_FooterButtons {
    display: flex;
    gap: 10px;
}

.LY_FileImportModule_Modal_Table_Error{
    text-align: start !important;
}
