/* .DetailPopupModal{
   
    width: 50vw;
} */
/* 
causes issues */
.DetailPopupModalContainer{
     height:50vh !important;
     overflow: auto;
     max-height: 50vh;
     width:100%;
} 
.DetailPopupModalFormContainer{

    display:grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr !important;
    grid-gap: .5rem;
    
    
 }

 .ListViewRelationshipTabs{
    margin-top: 1rem;
    height: 100%;
 }
.ListViewRelationshipTabPanel{
    min-height: 100%;
  
}
