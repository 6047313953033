.LY_WorkspaceDropDown_OptionActive {
  background-color: #dbdbdb6a !important;
  color: #000 !important;
  padding-block: 3px !important;
  box-shadow: unset  !important;
}

.LY_WorkspaceDropDown_Footer {
  display: flex;
  align-items: center;
  gap: 10px;
  border-top: 1px solid #757B87;
  cursor: pointer;
  padding: 10px;
}

.LY_WorkspaceDropDown_Option {
  display: flex;
  gap: 10px;
  align-items: center;
  font-weight: 400;
  padding: 5px 0;
}
 
.LY_WorkspaceDropDown_SearchInput {
  border-bottom: none !important;
  margin-bottom: 0 !important;
  min-height: unset !important;
  height: unset !important;
  line-height: unset !important;
  width: 100% !important;
  flex:1 !important;
  border: 1px solid #dcdee0 !important; 
  padding: 5px !important;
  border-radius: 8px !important;
  padding-inline: 10px !important;
  margin-inline: 0 !important;
 
}

.LY_WorkspaceDropDown_SearchInputContainer {
  margin: 3px !important;
  padding: 5px !important;
  margin-bottom: 0 !important;
  padding-bottom: 3px !important;
  margin-top: 10px !important;
}

.LY_WorkspaceDropDown_SelectedItem {
  padding:0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  background-color: #ffffff !important;
  gap: 3px;
}


 

.LY_WorkspaceDropDown_OptionsContainer {
  padding: 0 !important;
  border-radius: 5px !important;
  max-height: 50vh !important;
}
.LY_WorkspaceDropDown_EditContainer{
  border: unset !important;
}
.LY_WorkspaceDropDown_Container{
  width: 100%;
  margin-bottom: 5px;
}
.LY_WorkspaceDropDown_WorkspaceName{
   
  overflow: hidden;
  text-overflow: ellipsis;
}

.LY_WorkspaceDropDown_SelectedItem_Info {
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
   gap:5px;
   width: 100%;
}
.LY_WorkspaceDropDown_SelectedItem_arrow{
  padding-top: 10px;
}

.LY_WorkspaceDropDown_WorkspaceName_Selected{
  display: block;
  color: #535964;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  flex:1;
  max-width: 90% !important;
}