.LY_ListFiltersModule_Save_Modal_Buttons {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 10px;
}

.LY_ListFiltersModule_Save_button {
  border-radius: 4px !important;
  padding-left: 0px !important;
  padding-left: 15px !important;
}

.LY_FiltersModule_Save_RefreshBtn{
  
  border-radius: 4px !important;
 
   
}