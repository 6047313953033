.LY_AccountSetupContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  min-height: 80vh;
}

.LY_AccountSetupStepper {
  flex: 1;

}

.LY_AccountSetup_MainPageContainer {

  width: 60vw !important;
  min-width: 1000px;
  margin-bottom: 20px;
}



@media (max-width:600px) {
  .LY_AccountSetup_MainPageContainer {
    min-width: 100%;
 
  }
}

@media (max-width:950px) {
  .LY_AccountSetup_MainPageContainer {
    min-width: unset;
    width: 95% !important;
    margin-inline: 10px !important;
  }
}

.LY_AccountSetup_Stepper_content {
  padding: 1rem !important;
}

.LY_AccountSetup_AccountSetupGrid {
  /*  max-width: 1200px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
 */
  display: flex;

}

.LY_AccountSetupGridItem {
  width: 400px;
}


.LY_AccountSetup_Limited {
  color: red;
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
}

.LY_AccountSetup_Title {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 1rem;

}

.LY_AccountSetup_SubTitle {
  font-size: 1.5rem !important;
  font-weight: 400;
  margin-bottom: 1rem;

}

/* .MainLayoutContent {
  height: 100% !important;
} */





.LY_AccountSetup_MainPanels {
  max-width: 70vw !important;

}



.LY_AccountSetup_MainPanelsContent {
  margin-inline: 10px;
  padding: .7rem !important;
  background-color: white;
  border-radius: 0;
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
  width: 50vw !important;

}

@media (max-width: 768px) {
  .LY_AccountSetup_MainPanels {
    max-width: 100vw !important;

  }
}