.LY_NewWorkspaceModule_Form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
}

.LY_NewWorkspaceModule_Form_GlobalError {
    color: red;
    text-align: center;
}

.LY_NewWorkspaceModule_Form_TagInputContainer {
    display: flex;
    justify-content: space-between;
}

.LY_NewWorkspaceModule_Form_BadgeContainer {
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 1rem;
    padding-left: 0;
}

.LY_NewWorkspaceModule_Form_BottomButtons {
    margin-top: 10px;
    display: flex;
    justify-content: end;
    gap: 10px;
}