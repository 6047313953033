.LY_ComposeEmail_EmailPart_Container{
    padding-top: 20px;
}

.LY_ComposeEmailModule_SlashEditor_Top_Container{
    width: 100%;
}

.LY_ComposeEmail_EmailPart_Form_Btn{
    margin-left: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
    background-color: #007AFF;
    color: white;
    border-radius: 4px;
    padding: 10px 16px;
    width: 68px;
    height: 76px;
    cursor: pointer;
}

.LY_ComposeEmail_EmailPart_Form{
    font-size: 14px;
    color: #11161F;
    display: flex;
    gap: 16px;
    border-bottom: 1px solid #CED4DA;
}

.LY_ComposeEmail_EmailPart_Form_Lines{
    flex: 1;
}

.LY_ComposeEmail_EmailPart_Form_To_Cc_Active{
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
}

.LY_ComposeEmail_EmailPart_Form_Subject{
    padding: 6px 12px;
}

.LY_ComposeEmail_EmailPart_Form_Cc{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 6px;
}

.LY_ComposeEmail_EmailPart_Form_From{
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 6px 12px;
    border-bottom: 1px solid #CED4DA;
}

.LY_ComposeEmail_EmailPart_Subject_Input_Container{
    width: 100%;
}

.LY_ComposeEmail_EmailPart_Subject_Input{
    border: none ;
    padding: 0;
}

.LY_ComposeEmailModule_Cc_Span {
    cursor: pointer;
}

.LY_ComposeEmail_EmailPart_Form_To_Line{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
}

.LY_ComposeEmail_EmailPart_Form_To_Span{
    margin-right: 2px;
}

.LY_ComposeEmail_EmailPart_Form_To{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 12px;
    border-bottom: 1px solid #CED4DA;
}

.LY_ComposeEmail_Email_Badge{
    padding: 2px 5px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    gap: 2px;
    background-color: red;
    font-size: 14px;
    color: white;
    background-color: #007AFF;
}