.LY_StaticDropDownDisplay {
    display: flex;
/*     padding: 0 5px; */
    align-items: center;
    height: 30px;
    column-gap: 5px;

   
}
.LY_StaticDropDownDisplayArrow{
 color: var(--input-section-color, var(--mantine-color-dimmed)) !important
}

.LY_StaticDropDownContainer {
    display: flex;
    gap: 5px;
    border: 1px solid var(--input-bd);
    padding-block: 5px;
    cursor: pointer;
    min-width: 80px;
    width:100%;
    min-height: 35px;
    align-items: center;
}
.LY_StaticDropDownContainerMultiple {
    padding: 5px !important;

}
.LY_StaticDropDownDisplayBadgeViewTags {
    margin-inline: 5px !important;

}


.LY_StaticDropDown_View_Badge_CloseIcon{
    cursor: pointer;
 
}
.LY_StaticDropDown_View_Badge_CloseIcon:hover{
   font-weight: bold;
 height: 14px;
 width: 14px;
 color: red !important;
 
}

.LY_StaticDropDown_View_Badge_Label{
    justify-content: space-between;
 /*    overflow: auto !important; */
    height: unset !important;
    line-height: unset !important;
    min-height: 14px;
    padding:5px;
}