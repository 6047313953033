.LY_EmailAccountSetup_CustomForm_Container{
    display: flex;
    flex-direction: column;
    gap: 10px;
/*     padding: 35px 0px; */
    max-width: 800px;
    margin: 0 auto;
}

.LY_EmailAccountSetup_CustomForm_Line{
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.LY_EmailAccountSetup_CustomForm_InputContainer{
    width: 340px;
}

.LY_EmailAccountSetup_PortInput_Container{
    width: 125px !important;
}

