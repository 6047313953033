.LY_DotsIcon {
    cursor: pointer;
    
}

.LY_DotsIcon:hover {
    border-radius: 8px;
    background-color: #e0e0e0 !important;
    border: 1px solid var(--ly-global-background-color);

}