.LY_DLA_LabelMakerModule_Preview_Container{
    display: flex;
    flex-direction: row;
 
    justify-content: center;
    background-color: aliceblue;
    width: 100%;
    height: 100%;

    padding-top: 20px;

}
.LY_DLA_LabelMakerModule_Preview_Paper{
    display: flex;
    width:8.5in;
    justify-content: center;
    background-color: white;
    gap:0;
}
.LY_DLA_LabelMakerModule_Preview_PaperItems{
     display: grid;
    grid-template-columns: 4in 4in;
    column-gap: .15in;
    align-items: center;
  
/*     display: flex;
    flex-direction: row;
    max-width: 4in; */
/*     align-items: center;
    justify-items: center; */
  
}