.LY_ListFiltersModule_Filter_Footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.LY_ListFiltersModule_Filter_Footer>div {
  cursor: pointer;
  font-family: "Roboto";
  line-height: 18px;
  font-size: 12px;
  color: #535964;
  font-weight: 500;
}