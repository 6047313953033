.LY_NotesModule_SearchBar {
    
    column-gap: 12px;
}

.LY_Notes_Input {
    width: 100%;
}
.LY_Notes_Input > .mantine-Input-input {
    height: 40px;
    border-radius: 8px;
    padding-left: 40px;
}

.LY_Notes_Icon {
    cursor: pointer;
    border-radius: 8px;
    width: 40px;
    height: 40px;
    padding: 10px;
    border: 1px solid #ced4da;
}