.LY_MainContainerAlertContainer{
  max-width: 40vw;
  word-break: break-all;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  margin-bottom: 2rem;

}
.LY_MainContainerAlert{
  padding: 8px !important;
}

.LY_MainContainerContent{
  height: 100%;
 
/*   overflow: hidden; */
overflow:auto;
min-height: 50vh;
/*  padding-inline: 12px; */
}

.LY_MainContainerContentFullPageWidth{
 width: 100%;
 border-top-left-radius: 15px !important;
 border-top-right-radius: 15px !important;
 background-color: white;
 height: 100%;
 
}



.LY_MainContainerFullPageWidth{
  width: 100%;
  padding-inline: 10px;
  height: 100%;
 }
.LY_MainContainerAutoHeight{
  min-height: unset !important;
  height: auto !important;  
}