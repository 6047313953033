.LY_EmailAccountSetupModal_Container{
  display: flex;
  gap: 15px;
  margin-top: 20px;
}

.flexx{
  display: flex;
  flex-direction: column;
  border: 1px solid green;
  height: 60vh;
  width: 100%;
}

.boody{
  flex: 1;
  display: flex;
  flex-direction: column;
}

.instructions {
  flex: 1;
  border: 2px solid violet;
}