*{

  --ly-input-selected-bg-color: white;
/*   --ly-input-border-color:transparent ; */


}
.LY_TextInputContainer{

  padding: 0;
  margin:0;
}
.LY_TextInput{
  height: 100%;
  width: 100%;
  padding: 5px;
  border: 1px solid var(--ly-input-border-color);
  background-color: transparent;  
  border-radius: 0;
  margin:0;
}
.LY_TextInput:focus {
  box-sizing: border-box;
  /* border-color: #007BFF;   
  
  outline: none;*/
  outline: none;
/*   background-color: var(--ly-input-selected-bg-color); */
  border: 1px solid var(--ly-input-active-border-color);
  border-radius: 10;
  background-color: transparent;

}
.LY_TextInput:hover {
  background-color: var(--ly-input-selected-bg-color);
}
 