 .LY_Modal_Container{
 
    display: flex;
    flex-direction: column;
 }
 .LY_Modal_Body{
    gap:10px;
    display: flex;
    flex-direction: column;
 }
 .LY_Modal_ScrollArea {
   display: flex;
   flex-direction: column;
   gap: 10px;
   max-height: 60vh;
   padding-right: 5px;
}  
.LY_Modal_Footer{
      display: flex;
      flex-direction: row;
      gap:10px;
      justify-content: flex-end;
      align-items: center;
      padding-top:10px;
    /*   border-top: 1px solid #e0e0e0; */
}

.LY_Modal_Header{
   display: flex;
   flex-direction: row;
   padding-top:10px;
}