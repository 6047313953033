.LY_FileImportModule_Modal_Confirm {
    z-index: 9999;
}

.LY_FileImportModule_Modal_Confirm_TitleWrapper {
    display: flex;
    gap: 20px;
}

.LY_FileImportModule_Modal_Confirm_Title {
    background: #FAAD1433;
    display: flex;
    align-items: center;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    justify-content: center;
}

.LY_FileImportModule_Modal_Confirm_Content {
    padding-left: 30px;
    padding-left: 30px;
}

.LY_FileImportModule_Modal_Confirm_Buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 50px;
}

.LY_FileImportModule_Modal_Table_UploadStatus{
    display: flex;
    justify-content: center;
    align-items: center;
}
