.addColumnCell {
  border-right: 0 !important;
  position: unset !important;
  width: 70px;
  max-width: 70px;
  padding: 0 !important;
}


.LY_ListTableContainer{
  height: auto;
  width: 100%; 
  border: 1px solid var(--ly-global-background-color);
 /*  border-bottom: 0; */
}

.LY_ListHeaderCell{
   border-color: var(--ly-global-background-color) !important;

}
.MuiDataGrid-cell:focus  {
  outline: unset !important;
}

.addNewItemContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 2px;
 justify-content: start;
  margin-right: 10px;
/*   height: 0;
  position: relative;
  top: -25px; */
  width: 300px;
}
.addNewItemContainerNoFooter {
  top: 0px;
  height: 42px;
}


.addNewItemInput {
  width: 100%;
  height: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 5px;
  border: 0;
  font-size: 1.1rem;
  width: 200px;

  outline: 1px solid #f5f5f5;
  border-radius: 5px;
}

.addNewItemInput:focus {

  outline: 1px solid #007BFF;
  border-radius: 5px;

}

.MuiDataGrid-pinnedColumns,
.MuiDataGrid-pinnedColumnHeaders {
  box-shadow: none !important;
}
.LY_ListColumnCell {
 overflow:unset !important;
 display:block;
}
/* .LY_ListColumnCell :div {
  height: 100%;
  align-items: center;
  display: flex;
  width: 100%;
} */


.LY_ListColumnCellReadOnly {
  /* padding: 10px; */
  margin: 10px;
  width: 100%;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ListRightPanelContent,
.ListTableViewContent {

  padding: .5rem;

  overflow: auto  !important;  
  height: calc(100vh - 153px)  !important;
  /* height: 50vh; */
/*   min-height: 100vh  !important; */
/*   background-color: blue; */
}


.MuiDataGrid-root {
  font: inherit !important;
  font-size: inherit !important;
  color: inherit !important;

}
.ListTableViewContentPanelGroup{
  min-height: 100% !important;
  height: 100% !important;
  overflow: auto !important;
}

.ListTableViewContent::-webkit-scrollbar {
  width: var(--ly-global-scrollbar-width);
}
.ListTableViewContent::-webkit-scrollbar-track {
  background: var(--ly-global-scrollbar-track-color);
}

.ListTableViewContent::-webkit-scrollbar-thumb {
  background: var(--ly-global-scrollbar-thumb-color);
  cursor: pointer;
}

.ListTableViewContent::-webkit-scrollbar-thumb:hover {
  background: var(--ly-global-scrollbar-thumb-hover-color);
}

.ListPageContentPanelGroup{
  overflow: auto;
}
.MuiDataGrid-virtualScrollerContent{
   min-height: 35px !important;  
 
}

/* .MuiDataGrid-virtualScroller{
  min-height: 50vh !important;
  
 
} */

.LY_ListTableViewTableDropDowns_Container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
 
}
.MuiDataGrid-footerContainer{
  bottom: -52px;
  right: 0;
  height: 50px;
  position: absolute;
  z-index: 200;
  border: 0px !important;
}
.tableLinkBtn{
  margin-right: 10px !important;
}
.LY_ListTableFooter{
  display:'flex';
  justify-content:'space-between';
  padding-right:10px;
  min-height:55px;
  border-top: 1px solid var(--ly-global-background-color);
}
.LY_ListTableFooterSum{
  width: 100%;
  font-weight: bold;
  padding: 5px;
  padding-left: 8px;
}
.MuiDataGrid-aggregationColumnHeaderLabel{
  display: none !important;
}

.LY_List_TopBar{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: top;
  padding-right: 10px;
}