.LY_ListFiltersModule_Columns_button {
  color: #535964 !important;
}

.LY_ListFiltersModule_Columns_comboboxDropdown {
  width: 360px;
  padding: 10px !important;
}


.LY_ListFiltersModule_Columns_optionItem {
  height: 46px;
  display: flex;
  column-gap: 8px;
}

.LY_ListFiltersModule_Columns_optionItem_root {
  display: flex;
  align-items: center;
  width: 100%;
}

.LY_ListFiltersModule_Columns_optionItem_body {
  width: 100%;
}

.LY_ListFiltersModule_Columns_optionItem_label {
  width: 100%;
  height: 100%;
  display: flex;
}

.LY_ListFiltersModule_Columns_optionItem_labelWrapper {
  width: 100%;
}

.LY_ListFiltersModule_Filter_Columns_Header_Searchbar_Wrapper {
  margin: 8px 0;
}



.LY_ListFiltersModule_Columns_emptyOption {
  padding: 3px 12px;
  font-size: 12px;
  color: #a7aab0;
}

.LY_ListFiltersModule_Filter_Columns_Header_Searchbar_Root {
  margin: 6px 12px 10px 12px;
}

.LY_ListFiltersModule_Filter_Columns_Header_Searchbar_Input {
  padding-left: 38px !important;
  border-radius: 8px !important;
}


.LY_ListFiltersModule_Filter_button_Mobile {
  box-shadow: 0px 0px 4px 0px #00000026;
  padding: 6px;
  border-radius: 4px;
}