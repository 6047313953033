* {
 
  --dropdown-popup-bg: #ffffff; /* #fbfbfb; */


}

._LY_DropDownBaseViewEditContainerAutoComplete {
 
    border: 0px !important;
    background-color: transparent !important;
 
    color: var(--input-color) !important;
    opacity: 1 !important;
  }

._LY_DropDownBaseViewEditContainer {
/*   flex:1; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
/*   height: 90%; */
/*   margin-left: 2px; */
/*   margin: 3px; */
  border: 1px solid var(--input-bd);
  /* 
  margin: 10px; */
/*   margin-left: 0; */
/*   padding-left:0px; */
  gap: .6rem;
  cursor: pointer;
  background-color: transparent;
  
}
._LY_DropDownBaseViewEditContainerReadOnly{
  border: 0px !important;
  overflow: hidden;
  text-overflow: ellipsis;
/*   padding-left:5px; */
 
}
._LY_DropDownBaseViewEditContainerTableCell{
  border: 0px !important;
}
._LY_DropDownBaseViewEditContent, ._LY_DropDownBaseViewEditInput{
  flex:1;
 
}
._LY_DropDownBaseViewEditInputButton{
  padding-inline-end:unset !important;
  padding-inline-start:unset !important;
  border: unset !important;
  min-height: unset !important;
  padding: 0px !important;
  margin: 0px !important;
  height: unset !important;
  cursor: pointer !important;
  width: 100% !important;
  height: 100% !important;
  line-height: auto !important; /*  1.8rem !important; */
  display: flex !important;
  align-items: center !important;
   background-color: transparent !important;
  /* background-color: violet !important; */
}
._LY_DropDownBaseViewEditSelectedDisplayBadge{
/*   width: 100% !important; */
/*   height: 100% !important;
 */  min-width: 100px;
/*   line-height: unset !important; */
  text-transform: none !important;
  flex:1;
  margin: 4px;
}
._LY_DropDownBaseViewEditContainerViewMode{
  padding-left: 8px;
}

._LY_DropDownBaseViewEditDropdown{
/*   top:38px !important; */
  background-color: rgb(253, 253, 253) !important;
  border: 1px solid var(--ly-input-active-border-color) !important;
  padding: 3px !important;
  display: flex;
  flex-direction: column;
  z-index: 9001 !important;
}
._LY_DropDownBaseViewEditDropdown{

  .mantine-Popover-arrow{
    border-color: var(--ly-input-active-border-arrow-color);
   
  }

}


._LY_DropDownBaseViewEditSearch, ._LY_DropDownBaseViewEditSearchInput{
  background-color: unset !important;
}
/* ._LY_DropDownBaseViewEditDropdownTableCell{
  border:0px !important;

} */

._LY_DropDownBaseViewEditActions {

  display: none;
  /*   display: flex; */
/*   display: flex; */
  justify-content: center;
  align-items: center;
  gap: 5px;

}

._LY_DropDownBaseViewEditContainer:hover {

  ._LY_DropDownBaseViewEditActions {
    display: flex;
    
  }

}

._LY_DropDownBaseViewEditActionsButton{
  display: flex;
  justify-content: center;
  /* padding: 3px; */
  color: var(--mantine-primary-color-filled);
  cursor: pointer;
}

._LY_DropDownBaseViewEditActionsButton:hover{
  background-color: var(--action-btn-bg);
  color: var(--mantine-primary);

}

._LY_DropDownBaseViewEditLink{
  color: var(--mantine-color-black) !important;
  text-decoration: none;
}

._LY_DropDownBaseViewEditFooter{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 2px !important;
  margin:  0px !important;
  align-items: center;
  line-height: unset !important;
  border-color: #babcbd !important;
}
._LY_DropDownBaseViewEditFooterButton{
  color:gray;
}
._LY_DropDownBaseViewEditOptions{
  margin-block: 5px;
  max-height: 20vh;
 /*  height: 100%; */
  overflow-y: auto;
  overflow-x: hidden;
}
._LY_DropDownBaseViewEditOptions::-webkit-scrollbar {
  width: 8px;
 /*  height: 20px; */
}
._LY_DropDownBaseViewEditOptions::-webkit-scrollbar-track {
  background: transparent;/* (--ly-global-scrollbar-track-color); */
}
._LY_DropDownBaseViewEditOptions::-webkit-scrollbar-thumb {
  height: 30px; 
  background: var(--mantine-primary-color-filled);
  cursor: pointer;
}
 

._LY_DropDownBaseViewEditInputDownArrow{
  background-color: white;
 
  margin-left: 10px;
  cursor: pointer !important;
  z-index: 888;
}
._LY_DropDownBaseViewEditSearchContainer {
  display: flex;
  align-items: center;
}
._LY_DropDownBaseViewEditSearchInputWrapper{
  flex:1;
}