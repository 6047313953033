.LY_StatusDropdown_List_Renderer {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    padding: 4px;
    flex: 1 1;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 220px !important;
    padding-block: 5px;
}