.LY_FiltersModule_Filter {
  position: relative;
}

.LY_ListFiltersModule_Filter_comboboxDropdown {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  padding: 0 !important;
}

.LY_ListFiltersModule_Filter_header {
  padding: 10px 16px 0 16px !important;
}

.LY_ListFiltersModule_Filter_footer {
  padding: 10px 16px !important;
  background-color: #fcfcfc !important;
  box-shadow: 0px 1px 0px 0px #f0f0f0 inset;
}

@media screen and (max-width: 768px) {
  .LY_ListFiltersModule_Filter_header {
    padding: 10px 12px 0 12px !important;
  }

  .LY_ListFiltersModule_Filter_footer_actions {
    display: none;
  }
}


.LY_ListFiltersModule_Filter_body {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  padding: 0 16px 0 16px !important;
  row-gap: 5px;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 300px !important;
 
}


.LY_ListFiltersModule_Filter_Apply_button {
  color: #ffffff !important;
  border-radius: 4px !important;
}

.LY_ListFiltersModule_Filter_Cancel_button {
  border: 1px solid #a7aab0 !important;
  border-radius: 4px !important;
}

.LY_ListFiltersModule_Filter_Cancel_button_label {
  color: #262626;
}

@media screen and (max-width: 768px) {
  .LY_ListFiltersModule_Filter_comboboxDropdown {
    width: 100vw !important;
    height: 100vh !important;
    bottom: 0 !important;
    top: unset !important;
    z-index: 9999 !important;
  }

  .LY_ListFiltersModule_Filter_body {
    padding: 0 12px 0 12px !important;
    row-gap: 12px;
    max-height: unset !important;
  }
}

.LY_ListFiltersModule_Filter_button_Mobile {
  box-shadow: 0px 0px 4px 0px #00000026;
  padding: 6px;
  border-radius: 4px;
}