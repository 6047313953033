.LY_KanbanBoard_ItemList-wrapper {
    display: flex;
    flex-direction: column;
    transition: background-color 0.2s ease, opacity 0.1s ease;
    user-select: none;
    width: 100%;
    height: 100%;
    padding: 12px;
    overflow-y: auto;
}

.LY_KanbanBoard_ItemList-wrapper.dragging-over {
    background-color: #ffebe6;
}

.LY_KanbanBoard_ItemList-wrapper.disabled {
    opacity: 0.5;
}

.LY_KanbanBoard_ItemList-container {
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
    padding-right: 5px;
}

.LY_KanbanBoard_ItemList-list-container {
    display: flex;
    flex-direction: column;
}