.LY_EmailAccountSetup_EditModal_Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.LY_EmailAccountSetup_EditModal_Header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 18px;
    background-color: rgba(252, 252, 252, 1);
    box-shadow: 0px -1px 0px 0px rgba(240, 240, 240, 1) inset;
}

.LY_EmailAccountSetup_EditModal_Header span {
    font-size: 16px;
    font-weight: 700;
    color: #262626;
}

.LY_EmailAccountSetup_EditModal_Body{
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 5px 24px;
    flex: 1;
}

.LY_EmailAccountSetup_EditModal_Initials_Container{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 20px;
}

.LY_EmailAccountSetup_EditModal_SlashEditor_Container{
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.LY_EmailAccountSetup_EditModal_SlashEditor_Container span{
    font-size: 14px;
    font-weight: 400;
    color: rgba(83, 89, 100, 1);
}

.LY_EmailAccountSetup_EditModal_Status_Container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 150px;
}

.LY_EmailAccountSetup_EditModal_CampaignSettings_Heading{
    padding-bottom: 8px;
    font-size: 16px;
    color: rgba(83, 89, 100, 1);
    border-bottom: 1px solid rgba(83, 89, 100, 0.2);
}

.LY_EmailAccountSetup_EditModal_CampaignSettings_Container{
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.LY_EmailAccountSetup_EditModal_CampaignSettings_{
    display: flex;
    gap: 67px;
}

.LY_EmailAccountSetup_EditModal_CampaignSettings{
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.LY_EmailAccountSetup_EditModal_CampaignSettings span:first-of-type {
    font-size: 14px;
    font-weight: 400;
    color: rgba(83, 89, 100, 1);
}

.LY_EmailAccountSetup_EditModal_CampaignSettings span:nth-of-type(2) {
    font-size: 12px;
    color: rgba(83, 89, 100, 0.6);
}

.LY_EmailAccountSetup_EditModal_CampaignSettings_Info{
    display: flex;
    align-items: center;
    gap: 8px;
}

.LY_EmailAccountSetup_EditModal_CampaignSettings_Info div {
    padding: 6px 45px;
    border: 1px solid rgba(83, 89, 100, 0.3);
    font-size: 12px;
    color: rgba(83, 89, 100, 1);
}

.LY_EmailAccountSetup_EditModal_CampaignSettings_Info_Text {
    font-size: 12px !important;
    color: rgba(83, 89, 100, 1) !important;
}

.LY_EmailAccountSetup_EditModal_Reply_Container{
    display: flex;
    flex-direction: column;
}

.LY_EmailAccountSetup_EditModal_Footer{
    display: flex;
    justify-content: end;
    gap: 8px;
    padding: 10px 18px;
    background-color: rgba(252, 252, 252, 1);
    box-shadow: 0px 1px 0px 0px rgba(240, 240, 240, 1) inset;
}

.LY_EmailAccountSetup_EditModal_CampaignSettings_Header_Container{
    display: flex;
    align-items: center;
    gap: 5px;
}

.LY_EmailAccountSetup_EditModal_Header_Name_Container{
    display: flex;
    align-items: center;
    gap: 8px;
}

.LY_EmailAccountSetup_EditModal_Header_AccountName {
    color: rgba(83, 89, 100, 1) !important;
    font-size: 14px !important;
}

.LY_EmailAccountSetup_SlashEditor_Container{
    height: 200px !important;
}

.LY_EmailAccountSetup_EditModal_StatusDropDown_Container{
    height: 37px !important;
}