.tiptap-link {
    color: #6b7280;
    text-decoration: underline;
    text-underline-offset: 3px;
    transition: color 0.2s ease-in-out;
    cursor: pointer;
  }
  .tiptap-link:hover {
    color: #2563eb;
  }
  
  .tiptap-image-plugin {
    opacity: 0.4;
    border-radius: 0.75rem;
    border: 1px solid #e5e7eb;
  }
  
  .tiptap-image,
  .updated-image {
    border-radius: 0.75rem;
    border: 1px solid #d1d5db;
  }
  
  .task-list {
    padding-left: 0.5rem;
  }
  
  .task-item {
    display: flex;
    gap: 0.5rem;
    align-items: start;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  
  .horizontal-rule {
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    border-top: 1px solid #6b7280;
  }
  
  .bullet-list {
    list-style-type: disc;
    list-style-position: outside;
/*     line-height: 0.75rem; */
   /*  margin-top: -0.5rem; */
  }
  
  .ordered-list {
    list-style-type: decimal;
    list-style-position: outside;
    line-height: 1.5rem;
  /*   margin-top: -0.5rem; */
  }
  
  .list-item {
    line-height: 1.5rem;
 /*    margin-bottom: -0.5rem; */
  }
  
  .blockquote {
    border-left: 4px solid #2563eb;
  }
  
  .code-block {
    border-radius: 0.375rem;
    background-color: #f3f4f6;
    color: #6b7280;
    border: 1px solid;
    padding: 1.25rem;
    font-family: monospace;
    font-weight: 500;
  }
  
  .inline-code {
    border-radius: 0.375rem;
    background-color: #f3f4f6;
    padding-left: 0.375rem;
    padding-right: 0.375rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    font-family: monospace;
    font-weight: 500;
  }
  
  .youtube,
  .twitter {
    border-radius: 0.75rem;
    border: 1px solid #d1d5db;
  }
  
  .mathematics {
    color: #000;
    border-radius: 0.25rem;
    padding: 0.25rem;
    cursor: pointer;
  }
  .mathematics:hover {
    background-color: #f0f4ff;
  }
  