.LY_EmailAccountSetup_EmailProvider_Container{
    display: flex;
    align-items: center;
    border: 1px solid #CED4DA;
    cursor: pointer;
    gap: 12px;
    border-radius: 8px;
}

.LY_EmailAccountSetup_EmailProvider_Container_Active{
    outline: 2px solid rgb(9, 156, 255);
    border: 1px solid transparent;
}

.LY_EmailAccountSetup_EmailProvider_Img_Container{
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #CED4DA;
    padding: 10px 15px;
}
