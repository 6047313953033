.DetailPageModalFormContainer{
/*     grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr; */
    max-height: unset;
}
 .DetailPageModalFormContainerItem {
  gap:15px;
  flex-direction: row !important;
  align-items: center;
/*   align-items: flex-start !important;
  gap:0px !important; */

} 
.DetailPageModalFormContainerItem div{
  justify-content: right !important;
}

.DetailPageModalFormContainerItemStatus div{
  justify-content: center !important;
}

.LY_DetailPageRelationshipTabs{
  margin-top: 1rem;
}

.LY_DetailPageTopForm{

    padding: 10px;
}
.LY_DetailPagePanel{
  height: 100%;
}
.LY_DetailPageTabs{
  height: auto;
}
.LY_DetailPage_MainSplitPagePanelContent{
  overflow: auto;
}




/* Laptop */
@media (max-width: 1024px) {
    .DetailPageModalFormContainer {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }
  
  /* Tablet */
  @media (max-width: 768px) {
    .DetailPageModalFormContainer {
      grid-template-columns: 1fr 1fr;
    }
  }
  
  /* Mobile */
  @media (max-width: 480px) {
    .DetailPageModalFormContainer {
      grid-template-columns: 1fr;
    }
  }