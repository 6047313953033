 .LY_StatsGridContainer{

    padding-block: 10px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr)) !important;
    grid-auto-flow: row !important;
    gap:10px;
 }

 
 .LY_StatsTitle{

    font-weight: 500 !important;
    text-transform: uppercase;
    font-size: 1rem !important;
  }

  .LY_StatsTitleIcon {
    color: light-dark(var(--mantine-color-gray-4), var(--mantine-color-dark-3));
  }
 
  
  .LY_StatsTitleValue {
    font-size: 1.3rem !important;
    font-weight: 700 !important;
    line-height: 1;
    width:100%;
  }
  
 
  @media (max-width: 1400px) {
    
    .LY_StatsTitleIcon{
      display: none;
    }
  }
  

  @media (max-width: 500px) {
    .LY_StatsGridContainer {
      grid-template-columns: 1fr 1fr  !important;
    }
    .LY_StatsTitleIcon{
      display: none;
    }
  }