.LY_NotesModule_User_Card {
    display: flex;
    justify-content: space-between;
    justify-content: flex-start;
    align-items: center;
    column-gap: 6px;
}
.LY_NotesModule_User_Card_Author_Badge{
    padding: 0px 4px;
    background-color: #007AFF33;
    line-height: 22px;
    color: #007AFF;
    border-radius: 2px;
    font-size: 12px;
    
}

.LY_NotesModule_User_Card_Avatar{
    width: 40px!important;
    height: 40px !important;
}

@media (max-width: 480px) {
    .LY_NotesModule_User_Card_Reply span {
        font-size: 14px!important;
    }
}