.LY_EmailAccountSetup_EmailProvider_Instructions_Container{
    display: flex;
    flex-direction: column;
    gap: 12px;
    height: 100%;
}

.LY_EmailAccountSetup_EmailProvider_Instructions_SelectedProvider_Container{
    padding: 0 24px;
}

.LY_EmailAccountSetup_EmailProvider_Instructions_AuthTypes_Container{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
}

.LY_EmailAccountSetup_EmailProvider_Instructions_AuthType_Container{
    width: 150px;
    display: flex;
    gap: 10px;
    height: 50px;
    justify-content: center;
    align-items: center;
    border: 2px solid rgba(206, 212, 218, 1);
    border-radius: 8px;
    cursor: pointer;
}

.LY_EmailAccountSetup_EmailProvider_Instructions_AuthType_Container_Active {
    border: 2px solid rgb(9, 156, 255);
}

.LY_EmailAccountSetup_EmailProvider_AuthType_Instructions{
 /*    padding: 16px 32px; */
    word-break: break-word;
    padding-left: 20px;
    padding-right: 10px;
    max-height: 300px;

}

.LY_EmailAccountSetup_EmailProvider_Instructions_PasswordForm_Container{
    padding: 16px;
    border-left: 1px solid rgba(206, 212, 218, 1);
}

.LY_EmailAccountSetup_EmailProvider_Instructions_PasswordForm_Line {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.LY_EmailAccountSetup_EmailProvider_Instructions_BottomPart_Container{
    border-top: 1px solid rgba(206, 212, 218, 1);
    display: flex;
    flex: 1;
    gap: 10px;
}

.LY_EmailAccountSetup_EmailProvider_Instructions_BottomPart_Container > div {
/*     flex: 1; */
    min-width: 300px;
}

.LY_EmailAccountSetup_EmailProvider_Instructions_PasswordForm_Error{
    color: red;
    font-size: 18px;
    margin-bottom: 10px;
}
.LY_EmailAccountSetup_EmailProvider_Instructions_PasswordForm_Wrapper{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

@media (max-width: 1199px) {

    .LY_EmailAccountSetup_EmailProvider_Instructions_BottomPart_Container{
        flex-direction: column;
    }

    .LY_EmailAccountSetup_EmailProvider_Instructions_PasswordForm_Container{
        border: none;
        flex-direction: row;
        gap: 20px;
    }

    .LY_EmailAccountSetup_EmailProvider_Instructions_PasswordForm_Line {
        display: block;
    }

    .LY_EmailAccountSetup_EmailProvider_Instructions_PasswordForm_Line_Input_Container {
        width: 100% !important;
    }

    .LY_EmailAccountSetup_EmailProvider_Instructions_PasswordForm_Container div {
        flex: 1;
    }

}