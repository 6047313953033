.LY_BulkEmailSenderModule_ItemList_Container {
    border-right: 2px solid #e0e0e0;
    width: 150px;
    display: flex;
    gap: 5px;
    flex-direction: column;
    overflow-y: visible !important;

}

.LY_BulkEmailSenderModule_ItemList_Item_label {
    font-size: 12px !important;
    cursor: pointer !important;
}

.LY_BulkEmailSenderModule_ItemList_Item_header {
    font-weight: 600;
}



.LY_BulkEmailSenderModule_ItemList_Item {
    padding:5px !important;
    cursor: pointer !important;
    border-radius: 5px !important;
    padding-block: 3px !important;
    margin-right: 5px !important;
    padding-bottom: 5px !important;
}

.LY_BulkEmailSenderModule_ItemList_Item:hover {
    background-color: var(--ly-global-menu-item-background-color) !important;
    color: var(--ly-global-menu-item-text-color) !important;
 
}
 
.LY_BulkEmailSenderModule_ItemList_Item_active {
    background-color: var(--ly-global-menu-item-background-color) !important;
    color: var(--ly-global-menu-item-text-color) !important;
   

}