.LY_StaticSelect_Options_item {
    padding: 2px;
    position: relative;
    /* flex: 1; */
    background-color: #fff;
    border: 1px solid #CED4DA;
    border-radius: 8px;
}

.LY_StaticSelect_Options_item_color_picker {
    width: 18px;
    height: 18px;
    border-radius: 50% !important;
    cursor: pointer;

}
.LY_StaticSelect_Options_item_delete_div{
    padding-right:3px;
}

.LY_StaticSelect_Options_item_input_root {
    width: 100% !important;
}

.LY_StaticSelect_Options_item_input_wrapper {
    display: flex;
    width: 100%;
    padding: 1px;
    flex: 1 !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 4px;
    margin: 0 !important;
    margin-bottom: 0px !important;
    bottom: 0px !important;

}

.LY_StaticSelect_Options_item_left_section_wrapper {
    display: flex;
    flex-direction: row !important;
    position: absolute !important;
    align-items: center !important;
    justify-content: center !important;
    top: unset !important;
    left: 3px !important;
    margin: 0 !important;
    height: 100% !important;
    --input-margin-bottom: 0px !important;

}

.LY_StaticSelect_Options_item_left_section {
    display: flex;
    flex-direction: row !important;
    gap: 3px;
    padding-left: 10px;
    align-items: center !important;
    justify-content: center !important;
    height: 100% !important;
}

.LY_StaticSelect_Options_item_input {
    display: flex;
    font-size: 12px !important;
    flex: 1 !important;
    padding: 0 !important;
    margin: 0 !important;
    padding-left: 48px !important;
    border-radius: 8px !important;
    font-size: 12px !important;
}

.LY_StaticSelect_Options_item_picker_input_wrapper {
    margin-bottom: 5px !important;
    width: 100%;
}

.LY_StaticSelect_Options_item_picker_input {
    border-radius: 8px !important;
    border: 1px solid #CED4DA !important;
}

.LY_StaticSelect_Options_item_picker {
    z-index: 9999;
    background-color: #fff;
    padding: 10px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    row-gap: 10px;
    flex-direction: column;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 9px 28px 8px #0000000D
}

.LY_StaticSelect_Options_item_picker_input {
    border-radius: 8px !important;
    border: 1px solid #CED4DA !important;
}

.LY_StaticSelect_Options_item_advancedInput_wrapper {
    width: 100%;
    width: 200px;
}

.LY_StaticSelect_Options_item_advancedInput {
    border: 1px solid #CED4DA !important;
    border-radius: 8px !important;
    padding: 2px;
}

.LY_StaticSelect_Options_add_option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 12px;
    margin-bottom: 10px;
}

.LY_StaticSelect_Options_add_option_button {
    border: 1px solid #A7AAB0 !important;
    border-radius: 8px !important;
    /*   padding: 5px !important; */
}

.LY_StaticSelect_Options_add_option_button_inner {
    color: #535964 !important;
    font-size: 12px !important;

}

.LY_StaticSelect_Options_switch_track_label {
    font-size: 14px !important;
}

.LY_StaticSelect_Options_switch_body {
    display: flex;
    align-items: center;
    justify-content: center;
}

.LY_StaticSelect_Options_select_input {
    border-radius: 8px !important;
    border: 1px solid #CED4DA !important;
}

.LY_StaticSelect_Options_select_label {
    margin-bottom: 10px;
}

.LY_StaticSelect_Options {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    padding: 10px 0;
}

.LY_StaticSelect_Options_select_root {
    padding: 0 12px;
}

.LY_StaticSelect_Options_input {
    border-radius: 8px !important;
    padding-left: 30px !important;
}

.LY_StaticSelect_Options_input_wrapper {
    margin: 0 12px;
}

.LY_StaticSelect_Options_item_picker_closeIcon {
    align-self: flex-end;
}

.LY_MainContainerContent {
    overflow: unset !important;
}

.sortable-item {
    display: flex;
    align-items: flex-start;
    background-color: #fff;
    width: 100%;
    border-radius: 8px;
    column-gap: 5px;

}

.sortable-item:hover {
    background-color: #f9f9f9;
}

.sortable-item-handle {
    cursor: grab;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2px;
}

.LY_StaticSelect_Options_item_delete {
    padding-top: 5px !important;
    cursor: pointer;
}

.LY_StaticSelect_Options_item_picker_saturation {
    height: 70px !important;
}