.LY_AddColumnModule_NewColumnForm{
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: auto;
  font-size: inherit !important;
}

.LY_AddColumnModule_NewColumnScrollArea {
  gap: 10px;
  display: grid;
  grid-template-columns: 1fr;
  overflow: auto;
  height: 100%;
}
 
.LY_AddColumn_ColumnTypeRow{
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: start;
  justify-content: center;
}

/* ResponsiveModal.css */
.responsive-modal {
  max-height: 60vh; /* Default max height for large screens */
}
 

@media (max-width: 768px) {
  .responsive-modal {
    max-height: 80vh; /* For small screens */
  }
}
