

.LY_Pricing_MainPageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
 /*  overflow: auto !important; */
 }


.LY_Pricing_PricingGrid{
 /*  max-width: 1200px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
 */
  display: flex;
  align-items: center;

}
.LY_PricingGridItem{
  width:400px;
}


.LY_Pricing_Limited{
  color: red;
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
}
.LY_Pricing_Title{
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 1rem;
  text-align: center;

}
.LY_Pricing_SubTitle{
  font-size: 1.5rem !important;
  font-weight: 400;
  margin-bottom: 1rem;
  text-align: center;

}

/* .MainLayoutContent {
  height: 100% !important;
} */

.LY_Pricing_MainPanels {
  max-width: 70vw !important; 
  
}


.LY_Pricing_MainPanelsContent{
  margin-inline: 10px;
  padding:.7rem !important;
  background-color: white;
  border-radius:0;
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
}
@media (max-width: 768px) {
  .LY_Pricing_MainPanels {
    max-width: 100vw !important; 
    
  }
}
