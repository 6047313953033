.LY_BulkEmailSenderModule_ContainerModalBody{
    padding: 0px !important;
}
.LY_BulkEmailSenderModule_ContainerModalHeader{
    padding-bottom: 0px !important;
    padding-top: 0px !important;
    padding-left: 10px !important;
}

.LY_BulkEmailSenderModule_Container{
    display: flex;
    flex-direction: column;
    /* gap:10px; */
    min-height: 550px;
    height: 40vh;
   
    
}
.LY_BulkEmailSenderModule_Container_Main{

    flex:1;
    display: flex;
    flex-direction: row;
    gap:10px;
    padding:10px;
    padding-top: 0px;
    overflow-y: auto;
    width:900px;
}


.LY_BulkEmailSenderModule_Container_Buttons{
    display: flex;
    flex-direction: row;
    gap:10px;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px solid #e0e0e0;
    padding: 8px;
}
 