.LY_SlashEditor {
    display: flex;
    width: 100%;
 
    height: 100%;
  
    flex-direction: column;
    overflow: auto;
}

.LY_SlashEditor_Toolbar {
    width: 100%;
    display: flex;
    flex-direction:row;
    align-items: center;
  
    border-bottom: 1px solid #E5E7EB;
    padding: 4px;
}

.LY_SlashEditor_EditorContent {
    width: 100%;
    display: flex;
    flex-direction: column-reverse; 
    border: 1px solid #E5E7EB;
    height: 100%;
    max-height: 30vh;
/*     white-space: pre-wrap; */
}

.LY_SlashEditor_EditorContent > div:first-of-type {
   flex:1;
   height: 100%;
   display: flex;
   overflow: auto;
 
}
 
.LY_SlashEditor_Attributes {
    font-size: 1rem;
    line-height: 1.4rem;
    max-width: 100%;
    outline: none;
/*     min-height: 400px; */
    padding: 20px;
/*     max-height: 400px; */
    overflow-y: auto; 


    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;

}

.LY_SlashEditor_Command {
    z-index: 9999;
    height: auto;
    max-height: 330px;
    overflow-y: auto;
    border-radius: 0.375rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.2s ease-in-out;
    background-color: #ffffff;
}

.LY_SlashEditor_CommandItem {
    display: flex;
    background-color: #fff;
    width: 100%;
    align-items: center;
    gap: 0.5rem;
    border-radius: 0.375rem;
    padding: 0.25rem 0.5rem;
    text-align: left;
    font-size: 0.875rem;
    cursor: pointer;
}

.LY_SlashEditor_CommandItem:hover {
    background-color: #F8F9FA;

} 

.LY_SlashEditor_CommandItem_Icon {
    display: flex;
    height: 2.5rem;
    width: 2.5rem;
    align-items: center;
    justify-content: center;
    border-radius: 0.375rem;

    border: 1px solid #E5E7EB;
}
.LY_SlashEditor_CommandItem_Title {
    font-weight: 500;
}

.LY_SlashEditor_CommandItem_Description {
    font-size: 0.75rem;
}

.LY_SlashEditor_Attributes_Disabled {
    display: none;
}

.LY_SlashEditor_MenuButton {
    width: 25px !important;
    height: 25px !important;
    color: #099CFF !important;
    margin-left: 10px;
}


.drag-handle {
    position: fixed;
    opacity: 1;
    transition: opacity ease-in 0.2s;
    border-radius: 0.25rem;
  
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10' style='fill: rgba(0, 0, 0, 0.5)'%3E%3Cpath d='M3,2 C2.44771525,2 2,1.55228475 2,1 C2,0.44771525 2.44771525,0 3,0 C3.55228475,0 4,0.44771525 4,1 C4,1.55228475 3.55228475,2 3,2 Z M3,6 C2.44771525,6 2,5.55228475 2,5 C2,4.44771525 2.44771525,4 3,4 C3.55228475,4 4,4.44771525 4,5 C4,5.55228475 3.55228475,6 3,6 Z M3,10 C2.44771525,10 2,9.55228475 2,9 C2,8.44771525 2.44771525,8 3,8 C3.55228475,8 4,8.44771525 4,9 C4,9.55228475 3.55228475,10 3,10 Z M7,2 C6.44771525,2 6,1.55228475 6,1 C6,0.44771525 6.44771525,0 7,0 C7.55228475,0 8,0.44771525 8,1 C8,1.55228475 7.55228475,2 7,2 Z M7,6 C6.44771525,6 6,5.55228475 6,5 C6,4.44771525 6.44771525,4 7,4 C7.55228475,4 8,4.44771525 8,5 C8,5.55228475 7.55228475,6 7,6 Z M7,10 C6.44771525,10 6,9.55228475 6,9 C6,8.44771525 6.44771525,8 7,8 C7.55228475,8 8,8.44771525 8,9 C8,9.55228475 7.55228475,10 7,10 Z'%3E%3C/path%3E%3C/svg%3E");
    background-size: calc(0.5em + 0.375rem) calc(0.5em + 0.375rem);
    background-repeat: no-repeat;
    background-position: center;
    width: 1.2rem;
    height: 1.5rem;
    z-index: 50;
    cursor: grab;
  
    &:hover {
      background-color: var(--novel-stone-100);
      transition: background-color 0.2s;
    }
  
    &:active {
      background-color: var(--novel-stone-200);
      transition: background-color 0.2s;
      cursor: grabbing;
    }
  
    &.hide {
      opacity: 0;
      pointer-events: none;
    }
  
    @media screen and (max-width: 600px) {
      display: none;
      pointer-events: none;
    }
  }

  .LY_SlashEditor_ButtonText{
    padding: 2px 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 13px;
    color: #11161F;
}

.LY_SlashEditor_WithPopover {
    max-width: 400px;
} 

.LY_SlashEditor_ReadOnly_Popover{
    width:300px !important;
    max-height: 200px !important;
    overflow-y: auto !important;
    border-radius: 8px !important;

}