.LY_ListFiltersModuleFilterRow {
  display: grid;
  gap: 8px;
  grid-template-columns: 80px 1fr;
  align-items: center;
}

.LY_ListFiltersModuleFilterRow_wrapper {
  display: flex;

  align-items: center;
  justify-content: flex-start;
  /* padding-top: 10px; */
}

/* ._LY_DropDownBaseViewEditContainerAutoComplete {
  border-radius: 4px !important;
  border: 1px solid #a7aab0 !important;
  background-color: #fcfcfc !important;
} */

.LY_ListFiltersModuleFilterRow_filter {
  display: flex;
  gap: 8px;
  width: 100%;
  background-color: #f7f7f7;
  padding: 3px;
}

.LY_ListFiltersModuleFilterRow_filter>svg {
  margin-top: 10px;
}

.LY_ListFiltersModuleFilterRow_filter_settings {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 8px;
 
  width: 100%;
}

.LY_ListFiltersModuleFilterRow_filter_settings .LY_inputContainer {
  min-width: 150px !important;
}

.LY_ListFiltersModule_Filter_body_group>.LY_ListFiltersModuleFilterRow>.LY_ListFiltersModuleFilterRow_filter {
  padding: 0;
}
/* 
.LY_ListFiltersModuleFilterRow_filter_row_operator{
  width: 120px !important;
} */
.LY_ListFiltersModuleFilterRow_filter_row_column{
  flex:1 !important;
}
.LY_ListFiltersModuleFilterRow_deleteIcon{
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .LY_ListFiltersModuleFilterRow_filter_settings {
    grid-template-areas:
      "column column"
      "condition value";
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
  }
}

.LY_ListFiltersModuleFilterRow_condition {
  font-size: 14px;
  width: 70px;
  margin-bottom: 15px;
}