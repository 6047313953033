.DlaListRightPanelContainer{
    height: 100%;
}
.DlaListRightPanelContainerTabs {
    height: 100%;
}
.DlaListRightPanelContainerTabPanel{
 
    padding: 5px;
    padding-bottom: 1rem;
    height: 100%;

}
.DetailPageContainerTabPanel{
 

    max-height: 50vh;
}
/* .DlaListRightPanelContainerFormContainer{
    grid-template-columns: 1fr !important;
 
      
 } */

 .DlaListRightPanelContainerFormContainerItem {

    flex-direction: column;
    justify-content: start !important;
    align-items: flex-start !important;
    gap:0px !important;

  }
