.LY_UserDropDown_Option{
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  font-weight: 400;
  margin: 3px 5px !important;
  padding: 0px 2px !important;
}

.LY_UserDropDown_Option_Left{
  display: flex;
  gap: 10px;
}

.LY_UserDropDown_Selected_Single_Container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1px 5px;
}

.LY_UserDropDown_Selected_Single_Info{
  flex: 1;
  display: flex;
  justify-content: start;
  align-items: center;
  min-height: var(--input-height-xs) !important;
}

.LY_UserDropDown_OptionActive{
  background-color: var(--ly-dropdown-selected-item-bg-color) !important;
  color: var(--ly-dropdown-selected-item-text-color) !important;
  border-radius: 10px !important;
}

.LY_UserDropDown_Option_Line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  gap: 10px;
  margin: 2px 5px !important;
  padding: 2px 5px !important;
  background-color: white !important;
  color: black !important;
}

.LY_UserDropDown_Option_Line:hover{
  background-color: #f8f9fa !important;
}

.LY_UserDropDown_CurenntUser_Option_Container{
  border-bottom: 1px solid #CED4DA;
  padding: 12px;
}

.LY_UserDropDown_CurenntUser_Option_Container:hover {
  background-color: #F5F5F5;
}

.LY_UserDropDown_Avatar_Container{
  display: flex;
  justify-content: center;
  width: 30px;
  height: 30px;
}

.LY_UserDropDown_Menu_Button{
  background: transparent !important;
  color: #8C8C8C !important;
  padding: 0 !important;
}

.LY_UserDropDown_SelectedItem_Container {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: start;
  gap: 3px;
  width: 100%;
}

.LY_UserDropDown_Border{
  margin-top: 8px;
  border-bottom: 1px solid rgb(186, 188, 189);
}

