
.LY_UserAvatar_Author_Badge{
    padding: 0px 4px;
    background-color: #007AFF33;
    line-height: 22px;
    color: #007AFF;
    border-radius: 2px;
    font-size: 12px;
    
}
.LY_UserAvatar_User_Badge{
    padding: 0px 4px;
    background-color: #FAAD14;
    line-height: 22px;
    color: #FFFFFF;
    border-radius: 2px;
    font-size: 12px;
}

.LY_UserAvatar_Container{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}


