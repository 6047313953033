* {
 



  --input-border-color: var(--ly-global-background-color);
  --ly-input-border-error-color:var(--mantine-color-error);
}

.LY_TextAreaInputOld {
  padding: 2px;
  border: 1px solid var(--input-border-color);
  display: block;
  width: 100%;
  text-align: var(--input-text-align);
  color: var(--input-color);
  border: 1px solid var(--input-bd) ;
  background-color: var(--input-bg) ;
  height: var(--input-size);
 /*  line-height: var(--input-line-height); */
  font-size: var(--input-fz, var(--input-fz, var(--mantine-font-size-sm)));
  border-radius: var(--input-radius) !important;
  padding-inline-start: 8px;
  padding-inline-end: 2px;
 
  cursor: var(--input-cursor);
  overflow: var(--input-overflow);
  border-radius: 0px;
  min-height: 5rem;
  resize: vertical;

}

.LY_TextAreaInput{
  resize: vertical !important;
  padding: 0px;
  height: 38px;
  line-height: normal !important;
}

.LY_TextEditor_WithPopover_Root{
  padding: 5px !important;
}
.LY_TextAreaInput_WithResize {
  /* resize:vertical !important; */
  max-height: 200px !important;
/*   min-width: 300px; */
  overflow-y: auto !important;
  border-radius: 8px !important;
/*   border: 1px solid var(--ly-global-border-blue) !important; */
  width: 300px !important;
  line-height: normal !important;
}
.LY_TextAreaInputContainer {
  padding: 0px;
  margin:0;
  border:0;
  width: 100%;

}


.LY_TextAreaInput_focus:focus {
  border-color: var(--input-bd-focus);
 }
.LY_TextAreaInput_error {
  border-color: var(--ly-input-border-error-color);
 
}
.LY_TextArea_ReadOnly{
  min-height: 1.5rem;
}

.LY_TextEditor_WithPopover_Target {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; 
/*   height: fit-content;
 */ 
  cursor: pointer;
  padding:5px;
  min-width:80px;

}
.LY_TextEditor_WithPopover_Dropdown{
  min-width: 300px;
}