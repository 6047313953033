.LY_NotesModule {
    padding-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100%;
    max-width: 1000px;
    padding-bottom: 10px;

}
/* .LY_NotesModuleRightPanel {

  height: calc(100vh - 334px)  !important;
} */

.LY_NotesModule_scrollbar {
  padding-right: 8px;
  flex: 1;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
}
.LY_NotesModule_scrollbar::-webkit-scrollbar {
    width: 8px;
    margin-left: 10px;
}

.LY_NotesModule_scrollbar::-webkit-scrollbar-track {
    background: #CED4DA;
}

.LY_NotesModule_scrollbar::-webkit-scrollbar-thumb {
  background: #007AFF;
  cursor: pointer;
}

.LY_NotesModuleContainer {
flex:1;
display:grid;
grid-template-columns: 1fr 1fr;
grid-gap: 1rem;
}

.columnGap-12 {
  column-gap: 12px;
}

.padding-12 {
  padding: 12px!important;
}
