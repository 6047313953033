.LY_ComposeEmail_Navigation_Button {
    display: flex;
    flex-direction: column;
    color: rgb(17, 22, 31);
    gap: 2px;
    font-size: 12px;
}

.LY_ComposeEmail_Template_DropDown_Selected_Info{
    display: flex;
    align-items: center;
    gap: 5px;
}

.LY_ComposeEmail_DropDown_Input_Container{
    border: none ;
}

.LY_ComposeEmailModule_TemplateDD_Footer{
    padding: 12px;
    border-top: 1px solid #757B87;
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;
    color: #535964;
    font-weight: 400;
    cursor: pointer;
}