.LY_FileImportModule_Modal_FileStatus {
    text-align: center;
}

.LY_FileImportModule_Modal_FileStatus_Info {

    display: flex;
    align-items: center;
    flex-direction: column;

    border: 1px dashed #CED4DA;
    padding: 24px 16px;
    text-align: center;
}

.LY_FileImportModule_Modal_FileStatus_FileName {
    width: 500px;
    display: flex;
    align-items: start;
    justify-content: center;

}

.LY_FileImportModule_Modal_FileStatus_Progress {
    width: 400px;
    margin: 0 auto;
}

.LY_FileImportModule_Modal_TrashIcon {
    cursor: pointer;
    align-self: flex-end;
}