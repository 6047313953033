 
/* even if disabled, show normal */
.mantine-Checkbox-input:checked{
  background-color: var(--checkbox-color) !important;
  border-color: var(--checkbox-color) !important;
}
.mantine-Checkbox-icon{
   color: var(--checkbox-icon-color) !important;

}

.LY_CheckBoxInputContainerBody{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
} 
.LY_CheckBoxInputContainerBodyForm{
  display: flex;
  align-items: left;
  justify-content: start;
  width: 100%;
  height: 100%;
  margin-top: 3px;
}


.LY_CheckBoxInputContainer{
 
  width: 100%;
  height: 100%;
}

.LY_CheckBoxInputContainerRoot{
  width: 100% !important;
  height: 100% !important;
}
.LY_CheckBoxInputContainerRoot2{
  height: 100% !important;
}

.LY_CheckBoxInputContainerInner{
  cursor: pointer !important;
}
.LY_CheckBoxInputContainerInput, .LY_CheckBoxInputContainerInner svg{
  transition: unset !important;
  transition-duration: 0ms !important;

}