.LY_NoteModule_Update_Input_Container{
    border-radius: 8px!important;
    margin: 0 !important;
    padding: 15px 30px 15px 15px !important;
    line-height: 24px !important;
}

.LY_NoteModule_Update_Input_Wrapper{
    margin: 0 !important;
}


.LY_NoteModule_Update_Input_SendIcon {
    cursor: pointer;
    color: #007AFF;
}

.LY_NoteModule_Update_Input {
    margin: 0 !important;
    padding: 6px 20px !important;
}
