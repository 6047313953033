.SelectedItemActionsContainer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  position: sticky;
  bottom: 40px;
  z-index: 500;
}

.SelectedItemActionsForm {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  height: auto;
  padding: 0 12px;
  box-shadow: 0px 0px 8px 0px #b0b0b080;
  min-height: 50px;
  border-radius: 7px;
  border: 1px solid #ced4da;
  background-color: #ffffff;
  position: relative;
}

.SelectedItemActionsForm_Close {
  position: absolute;
  right: -10px;
  top: -10px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0px 0px 4px 0px #00000040;
  cursor: pointer;
}

.SelectedItemActionsButton_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.SelectedItemActionsButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px;
  cursor: pointer;
  margin: 3px;
  font-size: 14px;
  color: #535964;
}

.SelectedItemActionsCounter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 10px;
}

.SelectedItemActions_Badge {
  width: 40px;
  height: 40px;
  border: 1px solid #007aff !important;
  border-radius: 3px;
  font-size: 16px;
  color: #007aff;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.SelectedItemActionsCounter_Label {
  font-size: 14px;
  color: #535964;
}

.SelectedItemActionsContextMenuIcon {
  height: 16px;
  width: 16px;
  stroke: 1.5;
  color: #757B87;
}


@media screen and (max-width: 600px) {
  .SelectedItemActionsButton_label {
    display: none;
  }

  .SelectedItemActionsForm {
    gap: 10px;
  }

  .SelectedItemActionsCounter_Label {
    width: min-content;
  }
}