 
 
.WorkspaceDropdownContextMenuBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  
  border-radius: 5px;
/*   padding: 3px; */
/*   margin-top: 22px; */
  color: rgb(88, 87, 87);

}

.WorkspaceDropdownContextMenuBtn:hover {
  background-color: rgb(224, 224, 224);
  color: black; 
}


.WorkspaceDropdownContextMenuArrow {
  fill: rgb(147, 147, 147);
 width: 1rem;
 height: .3rem;
 
} 

.WorkspaceDropdownContextMenuContent,
.NewWorkspaceSubContent {
  z-index: 8000 !important;
 /*  min-width: 200px; */
  background-color: white;
/*   border-radius: 6px; */
  padding: 5px;
/*   box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2);
 */  
 box-shadow: var(--ly-global-modal-box-shadow);

  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
}
.WorkspaceDropdownContextMenuContent[data-side='top'],
.NewWorkspaceSubContent[data-side='top'] {
  animation-name: slideDownAndFade;
}
.WorkspaceDropdownContextMenuContent[data-side='right'],
.NewWorkspaceSubContent[data-side='right'] {
  animation-name: slideLeftAndFade;
}
.WorkspaceDropdownContextMenuContent[data-side='bottom'],
.NewWorkspaceSubContent[data-side='bottom'] {
  animation-name: slideUpAndFade;
}
.WorkspaceDropdownContextMenuContent[data-side='left'],
.NewWorkspaceSubContent[data-side='left'] {
  animation-name: slideRightAndFade;
}

.NewWorkspaceItem,
.NewWorkspaceCheckboxItem,
.NewWorkspaceRadioItem,
.NewWorkspaceSubTrigger {
  font-size: 13px;
  line-height: 1;
/*   color: var(--violet-11); */
  border-radius: 3px;
  display: flex;
  align-items: center;
  height: 30px;
  padding: 0 10px;
  position: relative;
/*   padding-left: 25px; */
  user-select: none;
  outline: none;
  cursor: pointer;
  
}
.NewWorkspaceSubTrigger[data-state='open'] {
/*   background-color: var(--mantine-color-blue-0);
  color: var(--violet-11); */

  background-color: var(--ly-global-menu-item-background-color);
  color: var(--ly-global-menu-item-text-color);
}
.NewWorkspaceItem[data-disabled],
.NewWorkspaceCheckboxItem[data-disabled],
.NewWorkspaceRadioItem[data-disabled],
.NewWorkspaceSubTrigger[data-disabled] {
  color: var(--mauve-8);
  pointer-events: none;
}
.NewWorkspaceItem[data-highlighted],
.NewWorkspaceCheckboxItem[data-highlighted],
.NewWorkspaceRadioItem[data-highlighted],
.NewWorkspaceSubTrigger[data-highlighted] {
/*   background-color: var(--mantine-color-blue-0);
  color: var(--mantine-color-blue-8); */

  background-color: var(--ly-global-menu-item-background-color);
  color: var(--ly-global-menu-item-text-color);
  border-radius: 8px;
}
 

.NewWorkspaceSeparator {
  height: 1px;
  background-color: var(--mantine-color-blue-2);
  margin: 5px;
}
 


.RightSlot {
  margin-left: auto;
  padding-left: 20px;
  color: var(--mauve-11);
}
[data-highlighted] > .RightSlot {
  color: white;
}
[data-disabled] .RightSlot {
  color: var(--mauve-8);
}
 
   

.NewWorkspaceItemIcon{
  margin-right: 5px;
  width: 20px;
  height: 20px;
/*   fill: var(--mantine-color-blue-0); */
  fill: var(--ly-global-menu-item-background-color);

}

