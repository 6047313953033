.LY_SlashEditor_ActionButtons_Container{
    display: flex;
    gap:4px;
}

.LY_SlashEditor_ButtonWithText{
    padding: 2px 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 13px;
    color: #11161F;
}